import { ThemeContext } from "@emotion/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  TableHead,
  Table,
} from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";

export const TablaFormatos = ({ rows }) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const columns = [
    {
      width: 50,
      label: "Nombre",
      dataKey: "foet_nombre",
    },
    {
      width: 50,
      label: "Descripción",
      dataKey: "foet_descripcion",
    },
  ];
  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              display: "table-cell",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              fontWeight: 600,
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  function rowContent(_index, row) {
    return (
      <>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
            sx={{
              display: "table-cell !important",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
            }}
            onClick={() => {
              navigate("/inventarios/etiquetas/" + row.foet_id);
            }}
          >
            {row[column.dataKey]}
          </TableCell>
        ))}
      </>
    );
  }

  return (
    <Paper style={{ height: 400, width: "100%" }} sx={{ boxShadow: "none" }}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        style={{
          boxShadow: "none",
          border: "1px solid #f2f3f3",
        }}
      />
    </Paper>
  );
};
