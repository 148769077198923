import { useDispatch } from "react-redux";
import "./App.css";
import RoutesIndex from "./router";
import { Box, Snackbar } from "@mui/material";
import { usePrinterId } from "./hooks/usePrinter";
import { useEffect } from "react";
import { SET_PRINTER_ID } from "./redux/reducers/app.reducer";
import { SnackbarProvider } from "notistack";

function App() {
	const dispatch = useDispatch();
	const { data: printerId } = usePrinterId({ initialData: null });

	useEffect(() => {
		if (printerId) {
			dispatch(SET_PRINTER_ID(printerId));
		}
	}, [printerId]);

	return (
		<SnackbarProvider maxSnack={3}>
			<Box sx={{ width: "100vw", height: "100vh" }}>
				<RoutesIndex />
			</Box>
		</SnackbarProvider>
	);
}

export default App;
