import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AUTH_LOGOUT } from "../redux/reducers/auth.reducer";

export const AvailableRequestContentTypes = Object.freeze({
    MULTIPART: "multipart",
    JSON: "json"
});

const selectApiHost = (state) => state.app.apiHost;
const selectToken = (state) => state.auth.token;

const selectApiHostAndToken = createSelector(
    [selectApiHost, selectToken],
    (apiHost, token) => ({ apiHost, token })
);

const useApi = () => {

    const dispatch = useDispatch();
    const { apiHost, token } = useSelector(selectApiHostAndToken);

    const noSessionPost = async (resource, data, options = {}) => {
        let reqOptions = {};
        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`,  data, reqOptions);
            return result;
        } catch (error) {
            return error;
        }
    };

    const get = async (resource, options = {}) => {
        try {
            return await axios.get(`${apiHost}${resource}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                ...options
            });
        } catch (error) {
            if (error?.response?.status === 401) {
                // showSessionMessageExpired();
                dispatch(
                    AUTH_LOGOUT()
                )
            }
            return error;
        }
    };

    const post = async (resource, data, options = {}) => {
        let reqOptions = null;
        if (options.dataType === "json"){
            reqOptions = {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
            };
        }else{
            reqOptions = {
                headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
            };
        }
        try {
            let result = await axios.post(options.hasCustomUrl ? resource : `${apiHost}${resource}`, data, reqOptions);
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                // showSessionMessageExpired();
                dispatch(
                    AUTH_LOGOUT()
                )
            }
            return error;
        }
    };

    const put = async (resource, data, options = {}) => {
        let reqOptions = null;
        reqOptions = {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        };
        try {
            let result = await axios.put(options.hasCustomUrl ? resource : `${apiHost}${resource}`, data, reqOptions);
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                // showSessionMessageExpired();
                dispatch(
                    AUTH_LOGOUT()
                )
            }
            return error;
        }
    };

    const del = async (resource) => {
        try {
            let result = await axios.delete(`${apiHost}${resource}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return result;
        } catch (error) {
            if (error?.response?.status === 401) {
                // showSessionMessageExpired();
                dispatch(
                    AUTH_LOGOUT()
                )
            }
            return null;
        }
    };

    return {
        noSessionPost,
        get,
        post,
        put,
        del
    };
}

export default useApi;
