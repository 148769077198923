import React, { useContext, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { useContactoHorarioMutation } from "../../../../hooks/contactos";
import { useQueryClient } from "react-query";
import { createUseStyles } from "react-jss";
import { Backdrop, Box, Chip, Fade, Grid, IconButton, Modal, Typography } from "@mui/material";
import { AddCircle, Close } from "@mui/icons-material";
import { getKeyZonaHoraria } from "../../../../@enums/Horarios.enum";
import swal from 'sweetalert2'
import Elevation from "../../../../components/Elevation";
import HorarioForm from "./horarioForm";

const useStyles = createUseStyles({
    contactoHorarios: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            // opacity: 0,
            transition: "all 0.2s ease-in-out",
            // marginRight: -30,
        },
        "&:hover": {
            // "& .section-buttons": {
            //     transform: "scale(1)",
            //     opacity: 1,
            //     marginRight: 0,
            // },
        },
    },
    horariosItems: {
        display: "grid",
        width: "100%",
    },
    horarioItem: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        borderRadius: 10,
        background: "#f5f5f5",
        cursor: "default",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.03)"
        }
    },
    modalContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    },
});

const style_modal = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center'
};

export const HorarioItem = ({ item, style, allowDeleteHorario, useApi = true, onDeleteHorario, refetch }) => {
    const styles = useStyles();
    const { mutateContactoHorario, isLoadingContactoHorarioMutation, resetContactoHorarioMutation } = useContactoHorarioMutation();
    const queryClient = useQueryClient();

    const getDiaSemana = (dia) => {
        switch (dia) {
            case 1:
                return "Lunes";
            case 2:
                return "Martes";
            case 3:
                return "Miércoles";
            case 4:
                return "Jueves";
            case 5:
                return "Viernes";
            case 6:
                return "Sábado";
            case 7:
                return "Domingo";

            default:
                return "";
        }
    };

    const handleClickEliminarHorario = () => {
        if (useApi) {
            mutateContactoHorario(
                {
                    usho_id: item.usho_id,
                    mutationType: "delete"
                },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            resetContactoHorarioMutation();
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Horario eliminado con éxito",
                                showConfirmButton: false,
                                timer: 800
                            })
                            refetch()
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Fallo al eliminar el horario",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al eliminar el horario",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        } else {
            queryClient.setQueryData(["contacto", "horarios"], (oldData) => oldData.filter(h => h.usho_id !== item.usho_id));
            if (onDeleteHorario) onDeleteHorario(item);
        }
    };

    const getHour = (hour) => {
        if (!hour) return "";
        if (typeof hour === "string") return hour.substring(0, 5);
        return hour.format("HH:mm");
    }

    return (
        <div className={styles.horarioItem} style={style}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}  >
                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Chip label="Días" color="primary"/>
                    </div>
                    <div>
                        <Typography sx={{marginBottom:-1.5}}>{getDiaSemana(item?.usho_diainicial)}</Typography>
                        <span style={{display:'flex', height:15}}> - </span>
                        <Typography>{getDiaSemana(item?.usho_diafinal)}</Typography>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Chip label="Horario" color="primary"/>
                    </div>
                    <div>
                        <Typography sx={{marginBottom:-1.5}}>{getHour(item?.usho_horainicial)}</Typography>
                        <span style={{display:'flex', height:15}}> - </span>
                        <Typography>{getHour(item?.usho_horafinal)}</Typography>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "start", flexDirection: "column", flex: 1 }}>
                    <div>
                        <Chip  label="Zona horaria"  color="primary" sx={{marginBottom:2}}/>
                    </div>
                    <div>
                        {
                            getKeyZonaHoraria(item?.usho_usohorario)?.replace("_", " ") || "Sin zona"
                        }
                    </div>
                </div>
                { allowDeleteHorario &&
                    <div style={{ display: "flex", width: 20, justifyContent: "start", alignItems: "start" }}>
                        <IconButton 
                            aria-label="save" 
                            variant="contained"
                            size="small"
                            onClick={handleClickEliminarHorario}
                            >
                                <Close sx={{fontSize:16}}/>
                        </IconButton>
                    </div>
                }
            </div>
        </div>
    );

}

const Horarios = ({cont_id, useApi = true, onDeleteHorario, horarios, refetch}) => {
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);

    const handleClickAgregarHorario = () => setShowModal(true);

    const handleDeleteHorario = (horario) => {
        if (!useApi) {
            onDeleteHorario(horario);
        }
    }

    return(
        <Elevation style={{ width: "100%", padding: "10px 20px", overflowY: "auto"}}>
            <Grid container spacing={0} >
                <Grid item xs={12} sx={{display:'flex', justifyContent: "space-between", marginBottom: 2}}>
                    <Typography style={{ fontSize: 14, fontWeight: "bold" }}>Horarios</Typography>
                    <div className="section-buttons" style={{ width: "auto" }}>
                        <IconButton 
                        aria-label="save" 
                        variant="contained"
                        size="small"
                        onClick={handleClickAgregarHorario}
                        >
                            <AddCircle  sx={{fontSize:16}}/>
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} sx={{display:'flex', justifyContent: "space-between", marginBottom: 2}}>
                        <div className={styles.horariosItems}>
                            {horarios?.map((horario) => (
                                <HorarioItem
                                    item={horario}
                                    key={`${horario.usho_id}_${horario.usho_horainicial}`}
                                    allowDeleteHorario={true}
                                    useApi={useApi}
                                    onDeleteHorario={handleDeleteHorario}
                                    refetch={refetch}
                                />
                            ))}
                        </div>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModal}
                onClose={closeModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={showModal}>
                        <Box sx={style_modal}>
                            <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <HorarioForm  key={'crear_hor'} closeModal={closeModal} cont_id={cont_id} refetch={refetch}/>
                            </div>
                        </Box>
                    </Fade>
            </Modal>
        </Elevation>
    )
}

export default Horarios