import React, { useContext, useState } from "react";
import { Modal, Fade, Box, Backdrop, Typography, Grid, TextField, Button} from "@mui/material";
import { CrearParte } from "../../../../hooks/crearParte";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert2'
import { ThemeContext } from "@emotion/react";

const CrearProducto = ({open, setOpen}) => {

    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    const theme = useContext(ThemeContext)
    const navigate = useNavigate();

    const {crearParteMutation} = CrearParte();
    const [errorName, setErrorName] = useState(false);

    const handleClose = () => setOpen(false);

    const onSubmit = (event) => {
        event.preventDefault()
        if(
            event.target.part_nombre.value !== ''
        ){
            crearParteMutation(
                {part_nombre: event.target.part_nombre.value},
                {
                    onSuccess: (data) => {
                        let id = data.data;
                        setOpen(false)
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Parte creada con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                navigate(`/inventarios/partes/${id}`);
                            }, 1800);
                        });
                    },
                    onError: (error) => {
                        setOpen(false)
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
            })
        }else{
            setErrorName(true)
        }
    };


    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{p:0, mb:2, pr:1}} className="center_item">
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    Nueva Parte
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:3, pr:1}} >
                                <TextField
                                    id="part_nombre"
                                    label="Nombre parte"
                                    name="part_nombre"
                                    size="small"
                                    fullWidth
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorName(false) :
                                        setErrorName(true)
                                    }}  
                                    sx={{color: theme.palette.primary.input}}
                                    error={errorName}
                                    helperText={errorName ? 'Ingrese un valor' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1, mt:2}} >
                                <Button variant="contained" 
                                        className="btn_system" 
                                        type="submit"
                                >
                                    Crear Parte
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Fade>
        </Modal>
    )
}

export default CrearProducto;