import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { GetCertificado } from "../../../../../../hooks/getEncuesta";
import { PreguntasEnum } from "../../../../../../@enums/tipo_pregunta.enum";
import PreguntaEncuesta from "./pregunta";
import swal from "sweetalert2";
import processStrings from "../../../../../../helpers/check_JSON_parse";
import shuffleArray from "../../../../../../helpers/arrayRandom";

const ModalEncuesta = ({
  open,
  setOpen,
  id_cuest,
  answers,
  setAnswers,
  onlyRead,
  cantidad,
  id_item,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxWidth: 1000,
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    pt: 3,
    display: "flex",
    justifyContent: "center",
    maxHeight: 550,
  };
  const { getCertificadoMutation } = GetCertificado();
  const formCert = useRef(null);
  const [encuesta, setEncuesta] = useState(null);
  const [preguntas, setPreguntas] = useState([]);
  const [arrayResp, setArrayResp] = useState([]);
  const [rang, setRang] = useState([]);
  let respuestas = useRef(null);

  useEffect(() => {
    if (id_cuest !== null && open) {
      getCertificadoMutation(id_cuest, {
        onSuccess: (data) => {
          setEncuesta(data);
          if (answers.length > 0) {
            setArrayResp(answers);
          } else {
            let ques = shuffleArray(data?.cuestionarios_preguntas);
            setPreguntas(ques);
            fill_data(data?.cuestionarios_preguntas);
          }
          let preguntas = [...data?.cuestionarios_preguntas];
          respuestas.current = preguntas.map((pregunta) => {
            return { pregunta, respuesta: null };
          });
        },
        onError: (error) => {
          alert.error(error);
        },
      });
    }
    if (!open) {
      setArrayResp([]);
    }
  }, [open]);

  const fill_data = (questions) => {
    let resp = questions.map((item) => {
      if (item?.cupe_tipo === PreguntasEnum.MEDICIONES) {
        let r = rang;
        let rngs = {
          id: item?.cupe_id,
          rangos: processStrings(item?.cupe_valorprellenado),
        };
        setRang([...r, rngs]);
      }
      return {
        cupe_id: item?.cupe_id,
        cure_respuesta:
          item?.stand_id !== null ||
          item?.cupe_tipo === PreguntasEnum.MEDICIONES_SIN_ESTANDAR
            ? []
            : processStrings(item?.cupe_valorprellenado),
        cure_pregunta: item?.cupe_pregunta,
        cure_descripcion: item?.cupe_descripcion,
        cure_tipopregunta: item?.cupe_tipo,
        cure_multiple: item?.cupe_multiple,
        cure_opciones:
          item.cupe_tipo === PreguntasEnum.MEDICIONES || item.cupe_tipo === PreguntasEnum.MEDICIONES_SIN_ESTANDAR
          ? item?.cupe_valorprellenado : item?.cupe_opciones,
        stand_id: item?.stand_id,
        standars: item?.standars,
        cues_id: id_cuest,
      };
    });
    setArrayResp(resp);
  };

  const change_answer = (id, value, tipo, multiple = false, id_rang = null) => {
    let ans = arrayResp;
    let index = ans?.findIndex((objeto) => objeto.cupe_id === id);
    if (tipo === PreguntasEnum.OPCIONES && multiple) {
      const set = new Set(ans[index]?.cure_respuesta);
      if (set.has(value)) {
        set.delete(value);
      } else {
        set.add(value);
      }
      ans[index].cure_respuesta = Array.from(set);
    } else if (tipo === PreguntasEnum.RANGO) {
      ans[index].cure_respuesta[id_rang].value = parseInt(value);
    } else if (
      tipo === PreguntasEnum.MEDICIONES ||
      tipo === PreguntasEnum.MEDICIONES_SIN_ESTANDAR
    ) {
      if (typeof value === "string") {
        ans[index].cure_respuesta[id_rang] = parseFloat(value);
      } else {
        ans[index].cure_respuesta[id_rang] = value;
      }
    } else if (tipo === PreguntasEnum.NUMERICO) {
      ans[index].cure_respuesta = parseFloat(value);
    } else {
      ans[index].cure_respuesta = value;
    }
    setArrayResp([...ans]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateEnc = () => {
    const invalid = arrayResp.some((obj) => {
      return (
        obj.cure_respuesta === null ||
        obj.cure_respuesta === "" ||
        obj.cure_respuesta === "null" ||
        (Array.isArray(obj.cure_respuesta) &&
          obj.cure_respuesta.length === 0) ||
        (Array.isArray(obj.cure_respuesta) &&
          obj.cure_respuesta.some((item) => item === null))
      );
    });

    return invalid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEnc()) {
      swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Campos sin llenar, favor de completar formulario",
        showConfirmButton: false,
        timer: 2500,
        customClass: {
          container: "cont_alt",
        },
      });
    } else {
      if (id_item !== null) {
        setAnswers(id_item, arrayResp);
        setArrayResp(null);
      } else {
        setAnswers(arrayResp);
      }
      setOpen(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{ zIndex: 4000 }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {encuesta !== null && open ? (
            <form
              ref={formCert}
              style={{ overflowY: "scroll", height: "500px", width: "100%" }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ p: 0, textAlign: "center" }}>
                  <Typography
                    id="transition-modal-title"
                    variant="h5"
                    component="h4"
                  >
                    {encuesta?.cues_nombre?.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    p: 0,
                    pl: 2,
                    pr: 2,
                    textAlign: "justify",
                    mb: 3,
                    mt: 2,
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="h8"
                    component="h4"
                  >
                    {encuesta?.cues_descripcion}
                  </Typography>
                </Grid>
                {encuesta?.cues_observaciones && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      p: 0,
                      pl: 2,
                      pr: 2,
                      textAlign: "justify",
                      mb: 3,
                      mt: 2,
                    }}
                  >
                    <Typography
                      id="transition-modal-title"
                      variant="h10"
                      component="h8"
                    >
                      {encuesta?.cues_observaciones}
                    </Typography>
                  </Grid>
                )}

                {preguntas.length > 0 && arrayResp.length > 0 ? (
                  <>
                    {
                    preguntas?.map((pregunta, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sx={{ p: 0, pl: 2, pr: 2, textAlign: "justify" }}
                      >
                        <PreguntaEncuesta
                          key={`pregunta_${index}`}
                          id={pregunta?.cupe_id}
                          pregunta={pregunta?.cupe_pregunta}
                          descripcion={pregunta?.cupe_descripcion}
                          tipoRespuesta={pregunta?.cupe_tipo}
                          opciones={pregunta?.cupe_opciones}
                          multiple={pregunta?.cupe_multiple}
                          handleChangeAnswer={change_answer}
                          predeterminado={pregunta?.cupe_valorprellenado}
                          formdata={formCert}
                          standar={pregunta?.standars?.stand_nombre}
                          resp={
                            arrayResp?.find(
                              (item) => item?.cupe_id === pregunta?.cupe_id
                            )?.cure_respuesta
                          }
                          cantidad={cantidad}
                          objs={pregunta?.standars?.stand_valores}
                          rangos={
                            pregunta?.cupe_tipo === PreguntasEnum.MEDICIONES
                              ? rang?.find(
                                  (item) => item?.id === pregunta?.cupe_id
                                )?.rangos
                              : null
                          }
                          disabled={onlyRead}
                        />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {arrayResp?.map((respuesta, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sx={{ p: 0, pl: 2, pr: 2, textAlign: "justify" }}
                      >
                        <PreguntaEncuesta
                          key={`pregunta_${index}`}
                          id={
                            onlyRead ? respuesta?.cure_id : respuesta?.cupe_id
                          }
                          pregunta={respuesta?.cure_pregunta}
                          descripcion={respuesta?.cure_descripcion}
                          tipoRespuesta={respuesta?.cure_tipopregunta}
                          opciones={respuesta?.cure_opciones}
                          multiple={respuesta?.cure_multiple}
                          handleChangeAnswer={change_answer}
                          predeterminado={respuesta?.cure_opciones}
                          formdata={formCert}
                          standar={respuesta?.standars?.stand_nombre}
                          resp={respuesta?.cure_respuesta}
                          cantidad={cantidad}
                          objs={respuesta?.standars?.stand_valores}
                          rangos={
                            respuesta?.cure_tipopregunta ===
                            PreguntasEnum.MEDICIONES
                              ? rang?.find(
                                  (item) => item?.id === respuesta?.cure_id
                                )?.rangos
                              : null
                          }
                          disabled={onlyRead}
                        />
                      </Grid>
                    ))}
                  </>
                )}

                {!onlyRead && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      p: 0,
                      pl: 2,
                      pr: 2,
                      textAlign: "justify",
                      mb: 3,
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      className="btn_system"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          ) : (
            <p>No se pudo cargar el certificado, favor de refrescar</p>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalEncuesta;
