import React, { useContext, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useApiContactoTelefonos, useContactoTelefonoMutation } from "../../../../hooks/contactos";
import swal from 'sweetalert2'
import { TelefonosEnum } from "../../../../@enums/tipos_telefono.enum";
import { Backdrop, Box, Button, Fade, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { AddCircle, Delete } from "@mui/icons-material";
import Elevation from "../../../../components/Elevation";
import { TableVirtuoso } from "react-virtuoso";
import LADAS_INTERNACIONALES from '../../../../@static_data/ladas-internacionales.json'
import MaskedInput from "react-text-mask";
import validateNumber from "../../../../helpers/validateInt";

const useStyles = createUseStyles({
    contactoTelefonos: {
        flex: 1,
        gap: 5,
        width: "100%",
        padding: 15,
        flexDirection: "column",
        "& .ant-form-item": {
            marginBottom: 12,
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1.1)",
            opacity: 0,
            transition: "all 0.2s ease-in-out",
            marginRight: -30,
        },
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
                marginRight: 0,
            },
        },
    },
    table: {
        width: "100%",
    },
    modalContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .ant-form-item": {
            flex: 1,
        },
    },
});


const style_mod = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center'
};


const TelefonosContacto = ({ cont_id }) => {
    const styles = useStyles();
    const theme = useContext(ThemeContext);
    const [showModal, setShowModal] = useState(false);
    const [tipoTelefono, setTipoTelefono] = useState(1);
    const formTel = useRef(null);
    const { data: telefonos } = useApiContactoTelefonos({ initialData: [] }, cont_id);
    const { mutateContactoTelefono, isLoadingContactoTelefonoMutation, resetContactoTelefonoMutation } = useContactoTelefonoMutation();
    const [errNum, setErrNum] = useState(false);
    const [errLada, setErrLada] = useState(false);
    const [whats, setWhats] = useState(false)
    const [ext, setExt] = useState(null)
    const mask = ["(", /[1-9]/, /\d/, /\d/, ")", /\d/, /\d/, /\d/,"-", /\d/, /\d/, /\d/, /\d/];

    const closeModal = () => setShowModal(false);

    const handleClickEliminarTelefono = (usc_id) => {
        mutateContactoTelefono(
            {
                mutationType: "delete",
                cont_id: cont_id,
                usc_id,
            },
            {
                onSuccess: (res) => {
                    if (res?.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Teléfono eliminado con éxito",
                            showConfirmButton: false,
                            timer: 800
                        })
                        closeModal();
                        resetContactoTelefonoMutation();
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al eliminar el teléfono",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al eliminar el teléfono",
                        showConfirmButton: false,
                        timer: 1500
                    })
                },
            }
        );
    };

    const columns = [
        {
            width: 30,
            label: 'Lada',
            dataKey: 'usc_lada',
        },
        {
            width: 50,
            label: 'Número',
            dataKey: 'usc_numero',
        },
        {
            width: 40,
            label: 'Tipo',
            dataKey: 'usc_tipo',
        },
        {
            width: 40,
            label: 'Usa Whatsapp',
            dataKey: 'usc_usawhatsapp',
        },
        {
            width: 20,
            label: 'Extension',
            dataKey: 'usc_extension',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const handleClickAgregarTelefono = () => {
        setShowModal(true);
    };
    const validateForm = () => {
        const regex = /_/g;
        if(formTel.current.usc_numero.value === '' ||
            formTel.current.usc_lada.value === '' ||
            formTel.current.usc_numero.value.match(regex) !== null
        ){
            if(formTel.current.usc_numero.value === '') setErrNum(true)
            if(formTel.current.usc_lada.value === '') setErrLada(true)
            if(formTel.current.usc_numero.value.match(regex) !== null) setErrNum(true)
            return false;
        }else{
            return true;
        }
    }
    const handleGuardarTelefono = () => {
        if(validateForm()){
            let values = {
                usc_numero: formTel.current.usc_numero.value,
                usc_tipo: parseInt(formTel.current.usc_tipo.value),
                usc_usawhatsapp: parseInt(formTel.current.usc_tipo.value) === TelefonosEnum.CELULAR ? 
                                whats : false,
                usc_lada: formTel.current.usc_lada.value,
                usc_extension: parseInt(formTel.current.usc_tipo.value) === TelefonosEnum.FIJO ? 
                                ext : null,
            }
            mutateContactoTelefono(
                {
                    mutationType: "create",
                    cont_id: cont_id,
                    ...values,
                },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            setTipoTelefono(1);
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Teléfono creado con éxito",
                                showConfirmButton: false,
                                timer: 800
                            })
                            closeModal();
                            resetContactoTelefonoMutation();
                            formTel.current.reset()
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Fallo al crear el teléfono",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al crear el teléfono",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    };

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        >
                            {column.dataKey === 'usc_usawhatsapp' ? (
                                <>
                                    {row['usc_usawhatsapp'] ? 'Sí' : ' No'}
                                </>
                            ):column.dataKey === 'usc_tipo' ? (
                                <>
                                    {row['usc_tipo'] === TelefonosEnum.CELULAR? 'Celular' : 'Fijo'}
                                </>
                            ):column.dataKey === 'acciones' ? (
                                <>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleClickEliminarTelefono(row?.usc_id)}
                                    >
                                        <Delete/>
                                    </IconButton> 
                                </>
                            ): (
                                (
                                    <>
                                        {
                                            row[column.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    return(
        <Elevation className={styles.contactoTelefonos}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Teléfonos</Typography>
                <IconButton size="small" onClick={handleClickAgregarTelefono} sx={{right:0, position:'absolute', marginRight:2}}>
                    <AddCircle sx={{ fontSize: 16 }}/>
                </IconButton>
            </div>
            <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                <TableVirtuoso
                    data={telefonos}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                    style={{
                        boxShadow:'none',
                        border:'1px solid #f2f3f3',
                        width: '100%'
                    }}
                />
            </Paper>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            onClose={()=>setShowModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            >
                <Fade in={showModal}>
                    <Box sx={style_mod}>
                        <form ref={formTel} style={{width: '100%'}}>
                            <FormControl fullWidth error={errLada} size="small" sx={{mb:1}}>
                                <InputLabel id="demo-simple-select-label">Lada</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="usc_lada"
                                    name="usc_lada"
                                    label="Lada"
                                    variant="standard"
                                    onChange={(e)=> {  
                                        setErrLada(false)
                                    }}
                                >
                                    {
                                        LADAS_INTERNACIONALES.map((lad) => (
                                            <MenuItem value={lad?.id}>{lad.name} (+{lad.lada})</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{errLada ? 'Seleccione una lada' : ''}</FormHelperText>
                            </FormControl>
                            <TextField
                            label="Número"
                            variant="standard"
                            name="usc_numero"
                            id="usc_numero"
                            fullWidth
                            InputProps={{
                                inputComponent: (props) => (
                                <MaskedInput {...props} mask={mask} />
                                ),
                            }}
                            sx={{mb:3}}
                            error={errNum}
                            helperText={errNum ? 'Ingrese un número válido': null}
                            />
                            <FormControl fullWidth size="small" sx={{mb:2, mt:2}}>
                                <InputLabel id="demo-simple-select-label">Tipo de télefono</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="usc_tipo"
                                    name="usc_tipo"
                                    label="Tipo de télefono"
                                    variant="standard"
                                    defaultValue={1}
                                    onChange={(e)=> {  
                                        setTipoTelefono(parseInt(e.target.value))
                                    }}
                                >
                                    <MenuItem value={1}>Celular</MenuItem>
                                    <MenuItem value={2}>Fijo</MenuItem>
                                </Select>
                            </FormControl>
                            {tipoTelefono === TelefonosEnum.CELULAR ? (
                                <FormGroup>
                                    <FormControlLabel 
                                    control={
                                        <Switch onChange={()=>setWhats(!whats)}/>
                                    } 
                                    label="Usa Whatsapp" />
                                </FormGroup>
                            ):(
                                <TextField
                                    id="usc_extension"
                                    size="small"
                                    label="Extensión"
                                    variant="standard"
                                    fullWidth
                                    onKeyDown={(e)=>
                                        {
                                        validateNumber(e)
                                        }
                                    }
                                    onKeyUp={(e)=>{
                                        setExt(e.target.value)}
                                    } 
                                    sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                    />
                            )}
                            <Button variant="contained" fullWidth onClick={handleGuardarTelefono} sx={{mt:3}}>Guardar</Button>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </Elevation>
    )
}   

export default TelefonosContacto;