import React, { useContext, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { Grid, TextField, Button } from "@mui/material";
import moment from "moment";
import './styles.css'


const HeaderProducts = ({data, save_data}) => {

    const theme = useContext(ThemeContext)
    const [name, setName] = useState(data.part_nombre)


    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{p:1, pl:2, pr:2}} className="center_item">
                <TextField
                    id="nombre_producto"
                    placeholder="Nombre del producto"
                    fullWidth
                    size="small"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={0} sm={0} md={0} lg={1} xl={1} sx={{p:1, pl:2, pr:2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} xl={2} sx={{p:1, pl:2, pr:2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <span style={{color: theme.palette.primary.input}} className="text_header">
                    Fecha creado
                </span>
                <span style={{color: theme.palette.primary.title}} className="text_header">
                    {moment(data?.part_fecharegistro).format("DD/MM/YYYY")}
                </span>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} xl={2} sx={{p:1, pl:2, pr:2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <span style={{color: theme.palette.primary.input}} className="text_header">
                    Creado por
                </span>
                <span style={{color: theme.palette.primary.title}} className="text_header">
                    Nombre usuario
                </span>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} xl={2} sx={{p:1, pl:2, pr:2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <Button variant="contained" className="btn_system" type="submit">
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}

export default HeaderProducts;