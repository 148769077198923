const validateNumber = (event) => {
    const key = event.key;
    const isBackspace = event.keyCode === 8;

    if (!isBackspace && !/[0-9\.\+\-]/.test(key) && key === 'Enter') {
        event.preventDefault();
        return false
    }else{
        return true
    }
}

export default validateNumber;