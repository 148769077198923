import React, { useContext, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ReimprimirEtiquetas } from "../../../../hooks/reimprimirEtiquetas";
import { TipoSplitEnum } from "../../../../@enums/tipo_split";
import { ThemeContext } from "@emotion/react";
import validateNumber from "../../../../helpers/validateInt";
import { Delete } from "@mui/icons-material";
import swal from 'sweetalert2'
import { DeleteMovimiento } from "../../../../hooks/deleteMovimiento";
import { useSelector } from "react-redux";
import { useLoading } from "../../../../components/Loading/useLoading";

const Spliteo = ({
  formData,
  paquetes,
  setPaquetes,
  piezas,
  setPiezas,
  errCantidad,
  setErrCantidad,
  tipoSplit,
  setTipoSplit,
  isUpdate,
  id,
  getData,
  openData, 
  closeItem, 
  movId
}) => {
  const printerId = useSelector((state) => state.app.printerId);
  const theme = useContext(ThemeContext);
  const {setAppLoading} = useLoading();
  const {reimprimirEtiquetasMutation} = ReimprimirEtiquetas();
  const {deleteMovimientoMutation} = DeleteMovimiento();
  const [reimpresion, setReimpresion] = useState([]);

  const changeTipoSplit = (value) => {
    setTipoSplit(parseInt(value));
    setPaquetes([]);
    setPiezas(0);
  };

  const fillDataRegular = () => {
    let contendedores = formData.current.total_contenedores.value;
    let piezas = formData.current.total_paquete.value;
    if (contendedores !== "" && piezas !== "") {
      let cont = parseInt(contendedores);
      let piez = parseInt(piezas);
      let total = cont * piez;
      let paquetes = new Array(cont);
      paquetes.fill(piez);
      setPaquetes(paquetes);
      setPiezas(total);
      setErrCantidad(false);
    } else {
      setPaquetes([]);
      setPiezas(0);
    }
  };

  const fillDataIrregular = () => {
    let piezas_paq = formData.current.total_cantidad_irr.value;
    if (piezas_paq !== "") {
      setPaquetes([...paquetes, parseInt(piezas_paq)]);
      setPiezas(piezas + parseInt(piezas_paq));
      setErrCantidad(false);
      formData.current.total_cantidad_irr.value = "";
    }
  };

  const deletePaqueteIrr = (index, value, deleteUpd) => {
    let paq = paquetes;
    if(deleteUpd){
      swal.fire({
        title: "¿Esta seguro de eliminar el contenedor?",
        text: "Se eliminaran todos los datos relacionados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar"
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        deleteMovimientoMutation(value?.id, {
          onSuccess: (data) => {
            swal.fire({
              position: "top-end",
              icon: "success",
              title: 'Contenedor eliminado',
              showConfirmButton: false,
              timer: 1500
            })
            paq.splice(index, 1);
            setPaquetes([...paq]);
            setPiezas(piezas - parseInt(paq.cantidad));
            setAppLoading(false);
          },
          onError: (error) => {
            setAppLoading(false);
            swal.fire({
              position: "top-end",
              icon: "error",
              title: "Hubo un error al eliminar",
              text: "Intenta de nuevo",
              showConfirmButton: false,
              timer: 1500
          })
          }
        })
      }
      
    })
    }else{
      paq.splice(index, 1);
      setPaquetes([...paq]);
      setPiezas(piezas - parseInt(value));
    }
  };

  const changeReimpresiones = (data) => {
    let reim = reimpresion;
    const indice = reim.findIndex(item => item?.data === data?.data);
    indice !== -1 ? reim.splice(indice, 1) : reim.push({id:data.mov, data:data.data });
    setReimpresion([...reim]);
  };

  const Reimprimir = () => {
    swal.fire({
      title: "Esta seguro de reimprimir las etiquetas",
      text: "Se eliminaran las etiquetas actuales y se asignaran las nuevas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, reimprimir"
  }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
          reimprimirEtiquetasMutation({printerId, etiquetas: reimpresion},{
              onSuccess: (data) => {
                  swal.fire({
                      position: "top-end",
                      icon: "success",
                      title: data.mensaje,
                      showConfirmButton: false,
                      timer: 1500
                  })
                  closeItem();
                  getData();
                  setAppLoading(false);
              },
              onError: (error) => {
                setAppLoading(false);
                  swal.fire({
                      position: "top-end",
                      icon: "error",
                      title: "Hubo un error al reimprimir",
                      text: "Intenta de nuevo",
                      showConfirmButton: false,
                      timer: 1500
                  })
                  
              }
          })
      }
  });
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          textAlign: "start",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Registro de contenedores
        </Typography>
      </Grid>

      {errCantidad ? (
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            textAlign: "start",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "red" }}>
            Ingrese valores en las cantidades de spliteo
          </p>
        </Grid>
      ) : null}
      {reimpresion.length > 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            textAlign: "start",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button variant="contained" className="btn_system" sx={{width:250}} disabled={!isUpdate} onClick={Reimprimir}>
                        Reimprimir etiquetas
                    </Button>
        </Grid>
      ) : null}

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        sx={{
          p: 1,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <FormControl fullWidth size="small" sx={{ width: "100%" }}>
          <InputLabel id="select-split">Tipo registro</InputLabel>
          <Select
            labelId="select-split"
            id="tipo_spliteo"
            name="tipo_spliteo"
            label="Tipo Spliteo"
            value={tipoSplit}
            disabled={isUpdate}
            onChange={(e) => changeTipoSplit(e.target.value)}
            required
          >
            <MenuItem value={1}>Regular</MenuItem>
            <MenuItem value={2}>Irregular</MenuItem>
          </Select>
        </FormControl>
        {tipoSplit === TipoSplitEnum.REGULAR && (
          <>
            <TextField
              id="total_contenedores"
              size="small"
              label="N° de Contenedores"
              fullWidth
              required
              disabled={isUpdate}
              onKeyDown={(e) => validateNumber(e)}
              onKeyUp={fillDataRegular}
              defaultValue={paquetes.length > 0 ? paquetes.length : ""}
              sx={{ color: theme.palette.primary.input, width: "100%" }}
            />

            <TextField
              id="total_paquete"
              size="small"
              label="Cantidad"
              fullWidth
              required
              disabled={isUpdate}
              onKeyDown={(e) => validateNumber(e)}
              onKeyUp={fillDataRegular}
              defaultValue={
                paquetes.length > 0
                  ? isUpdate && paquetes[0]?.cantidad
                    ? paquetes[0]?.cantidad
                    : paquetes[0]
                  : ""
              }
              sx={{ color: theme.palette.primary.input, width: "100%" }}
            />
          </>
        )}
        {tipoSplit === TipoSplitEnum.IRREGULAR && (
          <>
            <TextField
              id="total_cantidad_irr"
              size="small"
              label="Cantidad"
              fullWidth
              required
              disabled={isUpdate}
              onKeyDown={(e) => validateNumber(e)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  fillDataIrregular();
                }
              }}
              sx={{ color: theme.palette.primary.input, width: "100%" }}
            />
          </>
        )}
        <p style={{ fontSize: 16 }}>{`Total de Piezas: ${piezas}`}</p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        sx={{
          p: 1,
          gap: 2,
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          border: "1px solid #D9D9D9",
          borderRadius: 5,
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            p: 1,
            textAlign: "start",
            display: "flex",
            alignItems: "center",
          }}
        >
          {paquetes?.map((paq, index) => (
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              sx={{
                p: 1,
                textAlign: "start",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Chip
                label={
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        marginRight: 7,
                        fontSize: 14,
                        fontWeight: 400,
                        color: reimpresion.some((i) => i.data === paq?.tag?.id) || (!paq?.delete && isUpdate)
                          ? "white"
                          : "black",
                      }}
                    >
                      {isUpdate && paq?.cantidad ? paq?.cantidad : paq}
                    </span>
                    <span
                      style={{
                        marginLeft: 7,
                        border:
                          isUpdate && paq?.tag
                            ? reimpresion.some((i) => i.data === paq?.tag?.id) || !paq?.delete
                              ? "1px solid #c4cca4"
                              : "1px solid #575E3B"
                            : "none",
                        borderRadius: 4,
                        fontSize: 11,
                        padding: 3,
                        color: reimpresion.some((i) => i.data === paq?.tag?.id) || !paq?.delete
                        ? "#c4cca4"
                        : "#575E3B",
                      }}
                    >
                      {isUpdate && paq?.tag ? paq?.tag?.codigo : ""}
                    </span>
                  </div>
                }
                variant="outlined"
                deleteIcon={
                  (!isUpdate && tipoSplit === TipoSplitEnum.IRREGULAR) || (paq?.delete && !reimpresion.some((i) => i.data === paq?.tag?.id)) ? (
                    <Delete sx={{ marginLeft: "18px !important" }} />
                  ) : null
                }
                onDelete={
                  (!isUpdate && tipoSplit === TipoSplitEnum.IRREGULAR) || (paq?.delete && !reimpresion.some((i) => i.data === paq?.tag?.id))
                    ? () => deletePaqueteIrr(index, paq, (isUpdate && paq?.delete))
                    : null
                }
                onClick={
                  isUpdate && paq?.tag && paq?.delete
                    ? () => changeReimpresiones({mov: paq?.id, data:paq?.tag?.id})
                    : null
                }
                sx={{
                  height: 40,
                  borderRadius: 2,
                  display: "flex",
                  justifyContent:
                    tipoSplit === TipoSplitEnum.IRREGULAR && !isUpdate
                      ? "flex-end"
                      : "center",
                  width: "100%",
                  backgroundColor:
                    isUpdate && paq?.tag && paq?.delete
                      ? reimpresion.some((i) => i.data === paq?.tag?.id)
                        ? "#6c7547"
                        : "transparent"
                      : !paq?.delete && isUpdate
                        ? "rgba(0, 0, 0, 0.5)" 
                        : "transparent"
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Spliteo;
