import { Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useContext, useState } from "react"
import ModalEncuesta from "../../productos/components/modales/modalEncuesta/modalEncuesta";
import { Delete } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import { TipoSplitEnum } from "../../../../@enums/tipo_split";
import { AreaAlmacenEnum } from "../../../../@enums/tipo_almacen";
import validateNumber from "../../../../helpers/validateInt";

const ItemReport = ({movimiento, formData, index, item, almacenes, arraySplit, setArraySplit, setMovReporting, setIndexSelect, exist_question, cuestionario}) => {

  const theme = useContext(ThemeContext);
  const [openCert, setOpenCert] = useState(false);
  const [respuestas, setRespuestas] = useState(item?.respuestas)

  const changeCantidad = (index, value) => {
    let cant = 0;
    let rest = movimiento?.existencia_total;
    let movimientos = arraySplit; 
    let mov = movimiento;
    if(value !== ''){
      cant = parseInt(value);
      if(cant > (mov?.existencia_total+movimientos[index].cantidad)){
        cant = mov?.existencia_total+movimientos[index].cantidad;
        formData.current[`cantidad_${index}`].value = cant;
        rest = 0;
      }else{
        rest = mov?.existencia_total + (movimientos[index].cantidad-cant)
      }

    }else{
      rest = mov?.existencia_total + movimientos[index].cantidad;
      cant = null
      movimientos[index].split = []
    }
    movimientos[index].cantidad = cant;
    movimiento.existencia_total = rest;
    movimientos[index].tipoSplit = cant % 2 !== 0 ? TipoSplitEnum.IRREGULAR : TipoSplitEnum.REGULAR;
    setArraySplit([...movimientos])
    setMovReporting({...mov})
  }

  const changeOtherData = (index, name, value) => {
    let movimientos = arraySplit;
    movimientos[index][name] = value;
    if(name === 'almacen'){
      let almacen_siguiente = almacenes.find(item=>item.id === parseInt(value));
      if(almacen_siguiente.area === AreaAlmacenEnum.CUARENTENA){
        movimientos[index].master = false;
        movimientos[index].idCert = null;
      }else{
        let almacen_actual = almacenes.find(almacen => almacen.id === item?.almacen_actual);
        movimientos[index].idCert = cuestionario;
        if(almacen_actual.area === AreaAlmacenEnum.CALIDAD){
          movimientos[index].master = true
        }
      }
    }
    setArraySplit([...movimientos])
  }

  const changeTipoSplit = (index, value) => {
    let array = arraySplit;
    array[index].tipoSplit = value;
    array[index].split = [];
    setArraySplit([...array])
  };

  const fillDataRegular = (index, value) => {
    let array = arraySplit;
    let contendedores = value;
    let mov = movimiento; 
    if (contendedores !== "") {
      let cont = parseInt(contendedores);
      let paquetes = new Array(cont);
      let cociente = Math.floor(array[index].cantidad / cont);
      let residuo = array[index].cantidad % cont;
      paquetes.fill(cociente);
      if(residuo>0){
        let cantidad = parseInt(formData.current[`cantidad_${index}`].value)-residuo;
        mov.existencia_total += residuo;
        formData.current[`cantidad_${index}`].value = cantidad
        array[index].cantidad = cantidad;
      };
      array[index].split = paquetes;
    } else {
      array[index].split = []
    }
    setMovReporting({...mov})
    setArraySplit([...array])
  };

  const fillDataIrregular = (index, value) => {
    let array = arraySplit;
    let piezas_paq = value;
    if (piezas_paq !== "") {
      let new_value = parseInt(value)
      let total = array[index].split.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
      let rest = array[index].cantidad - total
      if(rest > 0){
        if( new_value > rest){
          array[index].split.push(array[index].cantidad - total)
        }else{
          array[index].split.push(new_value)
        }
      }
    }
    formData.current[`total_cantidad_irr_${index}`].value = "";
    setArraySplit([...array])
  };

  const deletePaqueteIrr = (index,index_split) => {
    let array = arraySplit;
    let splits = array[index].split
    splits.splice(index_split, 1);
    setArraySplit([...array]);
  };

  const openCertificado = (index) => {
    setIndexSelect(index);
    setOpenCert(true)
  }

  const saveCertificado = (index, resp) => {
    arraySplit[index].respuestas = resp
    setRespuestas(resp);
  }



  return (
    <>
      <Grid container spacing={0} sx={{border: "1px solid #D9D9D9", borderRadius: 5, mb:3}}>
              <Grid item xs={12} sx={{p:1}} className="center_item">
                <Grid container spacing={0}>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1}} className="center_item">
                    {movimiento?.codigo}
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1}} className="center_item">
                    {movimiento?.nombre}
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1}} className="center_item">
                    <TextField
                        id={`cantidad_${index}`}
                        size="small"
                        label="Cantidad"
                        fullWidth
                        required
                        defaultValue={index === 0 ? movimiento?.existencia_total : ''}
                        onKeyDown={(e)=> validateNumber(e)}
                        onKeyUp={(e)=>changeCantidad(index, e.target.value)}
                        sx={{color: theme.palette.primary.input}}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1}} className="center_item">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-alm">Almacen</InputLabel>
                      <Select
                        labelId="demo-simple-select-alm"
                        id="alma_id"
                        name="alma_id"
                        label="Almacen"
                        required
                        value={parseInt(item?.almacen)}
                        onChange={(e)=>changeOtherData(index, 'almacen', parseInt(e.target.value))}
                      >
                        {almacenes?.map((alm) => (
                          <MenuItem value={alm?.id}>{alm?.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1, flexDirection:'column'}} className="center_item">
                      <FormControl  disabled={almacenes.find(item=>item.id === arraySplit[index].almacen)?.area !== AreaAlmacenEnum.CALIDAD}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={item?.ok}
                          onChange={(e)=>changeOtherData(index, 'ok', JSON.parse(e.target.value))}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Ok" />
                          <FormControlLabel value={false} control={<Radio />} label="No Ok" />
                        </RadioGroup>
                      </FormControl>
                      <FormGroup>
                        <FormControlLabel control={
                          <Checkbox
                          checked={item?.master}
                          onChange={(e)=>changeOtherData(index, 'master', e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          disabled={
                            almacenes.find(item=>item.id === arraySplit[index].almacen_actual)?.area !== AreaAlmacenEnum.CALIDAD ||
                            almacenes.find(item=>item.id === +arraySplit[index].almacen)?.area === AreaAlmacenEnum.CUARENTENA
                          }
                          />
                        } label="Generar master" />
                      </FormGroup>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2} sx={{p:1}} className="center_item">
                    {exist_question &&
                      <Button
                      variant="contained"
                      className="btn_system"
                      sx={{ fontSize: 12, height: 35 }}
                      disableElevation
                      onClick={()=>openCertificado(index)}
                      >
                      Certificado
                      </Button>
                    }
                  </Grid>
                  <ModalEncuesta
                    key={`modal_enc_${index}`}
                    open={openCert}
                    setOpen={setOpenCert}
                    id_cuest={item?.idCert}
                    answers={respuestas}
                    setAnswers={saveCertificado}
                    onlyRead={false}
                    cantidad={item?.cantidad}
                    id_item={index}
                    />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{p:1}} className="center_item">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    sx={{
                      p: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                      flexDirection: "column",
                    }}
                  >
                      <FormControl fullWidth size="small" sx={{ width: "100%" }}>
                        <InputLabel id="select-split">Tipo registro</InputLabel>
                        <Select
                          labelId="select-split"
                          id="tipo_spliteo"
                          name="tipo_spliteo"
                          label="Tipo Spliteo"
                          value={item?.tipoSplit}
                          onChange={(e) => changeTipoSplit(index, parseInt(e.target.value))}
                          required
                        >
                          <MenuItem value={1}>Regular</MenuItem>
                          <MenuItem value={2}>Irregular</MenuItem>
                        </Select>
                      </FormControl>
                      {item?.tipoSplit === TipoSplitEnum.REGULAR && (
                        <>
                          <TextField
                            id={`total_contenedores_${index}`}
                            size="small"
                            label="N° de Contenedores"
                            fullWidth
                            required
                            onKeyDown={(e) => validateNumber(e)}
                            onKeyUp={(e)=>fillDataRegular(index, e.target.value)}
                            disabled = {item?.cantidad === null}
                            sx={{ color: theme.palette.primary.input, width: "100%" }}
                          />
                        </>
                      )}
                      {item?.tipoSplit === TipoSplitEnum.IRREGULAR && (
                        <>
                          <TextField
                            id={`total_cantidad_irr_${index}`}
                            size="small"
                            label="Cantidad"
                            fullWidth
                            required
                            disabled = {item?.cantidad === null}
                            onKeyDown={(e) => validateNumber(e)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                fillDataIrregular(index, e.target.value);
                              }
                            }}
                            sx={{ color: theme.palette.primary.input, width: "100%" }}
                          />
                        </>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={8}
                    sx={{
                      p: 1,
                      gap: 2,
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      border: "1px solid #D9D9D9",
                      borderRadius: 5,
                    }}
                  >
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        p: 1,
                        textAlign: "start",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.split?.map((paq, index_split) => (
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={3}
                          xl={3}
                          sx={{
                            p: 1,
                            textAlign: "start",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            label={
                              <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: 7,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color:"black",
                                  }}
                                >
                                  {paq}
                                </span>
                                <span
                                  style={{
                                    marginLeft: 7,
                                    border: "none",
                                    borderRadius: 4,
                                    fontSize: 11,
                                    padding: 3,
                                    color: "#575E3B",
                                  }}
                                >
                                  {""}
                                </span>
                              </div>
                            }
                            variant="outlined"
                            deleteIcon={
                              item?.tipoSplit === TipoSplitEnum.IRREGULAR ? (
                                <Delete sx={{ marginLeft: "18px !important" }} />
                              ) : null
                            }
                            onDelete={
                              item?.tipoSplit === TipoSplitEnum.IRREGULAR
                                ? () => deletePaqueteIrr(index, index_split)
                                : null
                            }
                            sx={{
                              height: 40,
                              borderRadius: 2,
                              display: "flex",
                              justifyContent:
                              item?.tipoSplit === TipoSplitEnum.IRREGULAR
                                  ? "flex-end"
                                  : "center",
                              width: "100%",
                              backgroundColor:"transparent",
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
      </Grid>
    </>
  )
}

export default ItemReport;