import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const ImprimirEtiqueta = () => {
  const api = useApi();

  const mutate = async (params) => {
    const { data } = await api.post(`/formatos-etiquetas/print/${params}`);
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    imprimirEtiqueta: mutation.mutate,
  };
};
