import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Input,
  FormLabel,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CreateFormato } from "../../../hooks/crearFormato";
export const EtiquetasNuevoFormato = () => {
  const dispatch = useDispatch();
  const { crearFormato } = CreateFormato();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    if (data.altura_ancho === "3 x 6") {
      data.foet_height = 6;
      data.foet_width = 3;
    }
    else if (data.altura_ancho === "3 x 4") {
      data.foet_height = 4;
      data.foet_width = 3;
    }
    delete data.altura_ancho
    crearFormato(data, {
      onSuccess: (data) => {
        navigate("/inventarios/etiquetas/" + data.foet_id);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  dispatch(HEADER_DATA_UPDATE({ nombreData: "Nuevo Formato" }));
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        padding: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel component="legend">Nombre</FormLabel>
          <Input
            placeholder="Nombre"
            {...register("foet_nombre", { required: true })}
            error={errors.foet_nombre}
            style={{
              marginRight: "20px",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend">Descripción</FormLabel>
          <Input
            placeholder="Descripción"
            {...register("foet_descripcion", { required: true })}
            error={errors.foet_descripcion}
            style={{
              width: "70%",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend">Altura y ancho</FormLabel>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "3 x 6");
              }}
              value={"3 x 6"}
              checked={watch("altura_ancho") === "3 x 6"}
            />
            <FormLabel component="legend">3 x 6</FormLabel>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "3 x 4");
              }}
              value={"3 x 4"}
              checked={watch("altura_ancho") === "3 x 4"}
            />
            <FormLabel component="legend">3 x 4</FormLabel>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "otro");
              }}
              value={"otro"}
              checked={watch("altura_ancho") === "otro"}
            />
            <FormLabel component="legend">Otro</FormLabel>
          </div>
          <Grid item xs={12}>
            {watch("altura_ancho") === "otro" && (
              <>
                <FormLabel component="legend">Ancho</FormLabel>
                <Input
                  placeholder="Ancho"
                  {...register("foet_width", { required: true })}
                  error={errors.foet_width}
                />
              </>
            )}
            {watch("altura_ancho") === "otro" && (
              <>
                <FormLabel component="legend">Altura</FormLabel>
                <Input
                  placeholder="Ancho"
                  {...register("foet_height", { required: true })}
                  error={errors.foet_height}
                />
              </>
            )}
            <FormLabel component="legend">Orientación</FormLabel>
            <Select
              defaultValue={"N"}
              {...register("foet_orientation", { required: true })}
            >
              <MenuItem value="N">Vertical</MenuItem>
              <MenuItem value="R">Horizontal</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Guardar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {watch("altura_ancho") !== "otro" && (
            <div
              style={{
                border: "1px solid black",
                width: 300,
                height:
                  watch("altura_ancho") === "otro"
                    ? watch("foet_height")
                    : watch("altura_ancho") === "3 x 4"
                    ? 400
                    : 600,
                rotate: watch("foet_orientation") === "R" ? "270deg" : "0deg",
              }}
            >
              <p
                style={{
                  paddingLeft: "10px",
                  position: "relative",
                  transform:
                    watch("foet_orientation") === "R"
                      ? "rotate(90deg)"
                      : "none",
                  left: watch("foet_orientation") === "R" && 120,
                  top: watch("foet_orientation") === "R" && 120,
                }}
              >
                {watch("altura_ancho")}
              </p>
            </div>
          )}
          {watch("altura_ancho") === "otro" &&
            watch("foet_height") != undefined &&
            watch("foet_width") != undefined && (
              <div
                style={{
                  border: "1px solid black",
                  width: watch("foet_width") * 100,
                  height: watch("foet_height") * 100,
                  rotate: watch("foet_orientation") === "R" ? "270deg" : "0deg",
                }}
              >
                <p
                  style={{
                    paddingLeft: "10px",
                    position: "relative",
                    transform:
                      watch("foet_orientation") === "R"
                        ? "rotate(90deg)"
                        : "none",
                    left: watch("foet_orientation") === "R" && 120,
                    top: watch("foet_orientation") === "R" && 120,
                  }}
                >
                  {watch("foet_width")} x {watch("foet_height")}
                </p>
              </div>
            )}
        </Grid>
      </Grid>
    </form>
  );
};
