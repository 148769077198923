/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Box, Button, Grid, Typography, IconButton } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import { RecursosEnum } from "../../../../@enums/tipo_images.enum";
import { CrearRecurso } from "../../../../hooks/createRecursos";
import { BorrarRecurso } from "../../../../hooks/delRecurso";
import icon_pdf from "../../../resources/imgs/pdf_icon.png"
import swal from 'sweetalert2'



const AyudasVisualesProductos = ({files, id_part, updateData}) => {

    const { apiResources } = useSelector((state) => ({
        apiResources: state.app.apiResources,
    }));
    const {crearRecursoMutation} = CrearRecurso();
    const {borrarRecursoMutation} = BorrarRecurso();
    const [showAlert, setShowAlert] = useState(false);


    const deleteImg = (recu_id) => {
        swal.fire({
            title: "¿Esta seguro de elimanar este archivo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar"
            
        }).then((result) => {
            if (result.isConfirmed) {
                borrarRecursoMutation(recu_id,{
                    onSuccess: (data) => {
                        if(data.success){
                            swal.fire({
                                title: "Eliminado",
                                text: "El archivo se elimino correctamente",
                                icon: "success"
                            }).then(()=>{
                                setTimeout(() => {
                                    updateData()
                                }, 800);
                            });
                        }else{
                            swal.fire({
                                position: "center",
                                icon: "error",
                                title: "Hubo un problema al eliminar el archivo, intentalo de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(()=>{
                                setTimeout(() => {
                                    updateData()
                                }, 1800);
                            });
                        }
                    },
                    onError: (error) => {
                        swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Hubo un problema al eliminar el archivo, intentalo de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                updateData()
                            }, 1800);
                        });
                    }
                })
            }
            
        });
    }

    const handleFileChange = (event) => {
        for (const file of event.target.files) {
            if (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                let data = {
                    part_id: id_part,
                    recu_tipo:  file.type === 'application/pdf' ? 
                                RecursosEnum.DOCUMENTO : 
                                RecursosEnum.AYUDA_VISUAL,
                }
                crearRecursoMutation({extraData: data, files: [file]}, {
                    onSuccess: (data) => {
                        updateData()
                    },
                    onError: (error) => {
                        console.log(error);
                    }
                })
                setShowAlert(false)

            }else{
                setShowAlert(true)
            }
        }
    };

    const imageList = files.map((file, index) => (
        <Grid key={file?.recu_id} item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:0, height:'100%', maxHeight:150, flexDirection:'column', mb:2, paddingLeft:1, paddingRight:1}} className="center_item">
            {file?.recu_mime.startsWith('image') ? (
                <Box key={file?.recu_id} sx={{ p:0, mb:1,border:'1px solid #e7e7e7', borderRadius:1, height:'100%', width:'100%', maxHeight:110}}>
                    <img src={`${apiResources}${file?.recu_ruta}`} alt="Imagen subida" loading="lazy" width='100%' height='100%' style={{borderRadius:5}} />
                </Box>
            ):(
                <a href={`${apiResources}${file?.recu_ruta}`} target="_blank" rel="noopener noreferrer"
                >
                    <Box key={file?.recu_id} sx={{ p:0, mb:1,border:'1px solid #e7e7e7', borderRadius:1, height:'100%', width:'100%', maxHeight:110}}>
                        <img src={icon_pdf} alt="icono pdf" width='100%' height='100%' loading="lazy" style={{borderRadius:5}} />
                    </Box>
                </a>
            )}
            <Grid container spacing={0}>
                <Grid item xs={10} sx={{textAlign:'center'}}>
                    <span style={{fontSize:10,textAlign:'center', overflowWrap:'break-word'}}>{file?.recu_nombre}</span>
                </Grid>
                <Grid item xs={2} className="center_item" sx={{position:'relative'}}>
                    <IconButton aria-label="delete" size="small" onClick={() => deleteImg(file?.recu_id)}>
                        <Delete fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid> 
    ));

    const UploadButton = () => (
        <div>
            <input type="file" accept="image/jpeg,image/png,image/jpg, application/pdf" id="contained-button-file" style={{ display: 'none' }} multiple onChange={handleFileChange} />
            <Button variant="outlined" component="label" htmlFor="contained-button-file"
                sx={{
                    display:'flex', 
                    flexDirection:'column',
                    height: 120,
                    width:'90%'
                }}>
                <AddCircle />
                <Typography>PNG, JPG, JPEG</Typography>
                
            </Button>
        </div>
    );

    return(
        <Grid container spacing={0}>
            {showAlert && (
                <Grid item xs={12} sx={{p:2}} className="center_item">
                    <Alert severity="error">
                        Solo se admiten archivos JPG, JPEG y PNG.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:0}} >
							<div>
								<input type="file" accept="image/jpeg,image/png,image/jpg, application/pdf" id="contained-button-file" style={{ display: 'none' }} multiple onChange={handleFileChange} />
								<Button variant="outlined" component="label" htmlFor="contained-button-file"
										sx={{
												display:'flex', 
												flexDirection:'column',
												height: 120,
												width:'90%'
										}}>
										<AddCircle />
										<Typography>PNG, JPG, JPEG</Typography>
								</Button>
							</div>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{p:0}} className="center_item">
                <Grid container spacing={0} sx={{height:'100%'}}>
                    {imageList}
                </Grid>
            </Grid>
        </Grid>
    )

}


export default AyudasVisualesProductos;