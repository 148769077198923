export const  EstatusMovimientoEnum  = Object.freeze({
  INACTIVO: 0,
  ACTIVO: 1,
  APARTADO: 2,
  EN_TRANSITO: 3,
  EN_RECONOCIMIENTO: 4,
  CANCELADO: 5,
  CARGADO: 6,
  PROCESADO: 7,
  ENVIADO: 8,
})