import React, { useContext, useEffect, useRef, useState } from "react";
import { useApiEmpresaCamposExtra, useEmpresaCamposExtraMutation } from "../../../../hooks/empresas";
import { useModal } from "../../../../hooks/use/useModal";
import { Backdrop, Box, Button, Fade, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { TiposCamposExtraEnum } from "../../../../@enums/tipo_campo_extra";
import { AddCircle, Delete } from "@mui/icons-material";
import { TableVirtuoso } from "react-virtuoso";
import validateNumber from "../../../../helpers/validateInt";
import ReactInputMask from "react-input-mask";
import { DateTime } from "luxon";
import swal from 'sweetalert2'


const initialFieldsValues = {
    came_nombre: null,
    came_tipo: 1,
    came_importante: false,
    came_valor: "",
    came_mascara: ""
};

const tipo_recursos_came = [
    {
        value: 1,
        label: "Documento"
    },
    {
        value: 2,
        label: "Movimiento-Documento"
    }
]

const tipo_doc_came = [
    {
        value: 1,
        label: "Entrada"
    },
]

const EmpresaCamposExtra = ({ emp_id }) => {

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    const theme = useContext(ThemeContext);
    const [showModal, setShowModal] = useState(false);
    const [editingValue, setEditingValue] = useState(null);
    const [tipoCampo, setTipoCampo] = useState(1);
    const [errNombre, setErrNombre] = useState(false);
    const [errTipo, setErrTipo] = useState(false);
    const [errDoc, setErrDoc] = useState(false);
    const formExt = useRef(null);

    const columns = [
        {
            width: 50,
            label: 'Nombre',
            dataKey: 'came_nombre',
        },
        {
            width: 20,
            label: 'Tipo',
            dataKey: 'came_tipo',
        },
        {
            width: 40,
            label: 'Valor',
            dataKey: 'came_valor',
        },
        {
            width: 20,
            label: 'Fecha de registro',
            dataKey: 'came_fecha',
        },
        {
            width: 10,
            label: 'Importante',
            dataKey: 'came_importante',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const { data: camposExtra, refetch: refetchCamposExtra,} = useApiEmpresaCamposExtra({
        intialData: [],
        id: emp_id,
    });
    const [visibleEditar, showModalEditar, closeModalEditar] = useModal();
    const { mutateEmpresaCamposExtra, isLoadingEmpresaCamposExtraMutation, resetEmpresaCamposExtraMutation } = useEmpresaCamposExtraMutation();

    const closeModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const validateForm = () => {

        setErrDoc(false);
        setErrNombre(false)

        if( (formExt.current.nombre.value === "") ||
            (formExt.current.tipo.value === "") || 
            (formExt.current.recurso?.value !== "" && 
            formExt.current?.documento?.value === "")
        ){
            if(formExt.current.nombre.value === "")setErrNombre(true)
            if(formExt.current.tipo.value === "")setErrTipo(true)
            if(formExt.current.recurso?.value !== "" && 
                formExt.current?.documento?.value === "")setErrDoc(true)
            return false;
        }else{
            return true;
        }
    }

    const handleCrearDatoExtra = () => {
        if(validateForm()){
            let extra = formExt.current.recurso.value ? 
                        JSON.stringify(
                            {
                                recurso: formExt.current?.recurso?.value,
                                tipoDocumento: formExt.current?.documento?.value
                            }
                        ) : null
            let values = {
                came_nombre: formExt.current.nombre.value,
                came_tipo: parseInt(formExt.current.tipo.value),
                came_importante: formExt.current.importante.checked,
                came_valor: formExt.current.valor.value,
                came_mascara: formExt.current?.mascara !== undefined ? formExt.current?.mascara?.value : null ,
                emp_id: emp_id,
                came_extradata: extra 
            }
            mutateEmpresaCamposExtra(
                {
                    ...values,
                    mutationType: "create",
                    emp_id,
                },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            resetEmpresaCamposExtraMutation();
                            formExt.current.reset();
                            closeModal();
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Campo extra creado con éxito",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Hubo un error al procesar",
                                text: "Intenta de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al procesar",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    };

    const handleClickDeleteCampo = (came_id) => {
        mutateEmpresaCamposExtra(
            {
                mutationType: "delete",
                emp_id,
                came_id
            },
            {
                onSuccess: (res) => {
                    if (res.success) {
                        resetEmpresaCamposExtraMutation();
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: `${res.mensaje}`,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al eliminar",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error al eliminar",
                        text: "Intenta de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    })
                },
            }
        );
    };
    
    useEffect(() => {
        refetchCamposExtra();
    }, [emp_id]);

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        >
                            {column.dataKey === 'came_tipo' ? (
                                <>
                                    {(row[column.dataKey] === TiposCamposExtraEnum.BOLEANO)?('Boleano'): 
                                    (row[column.dataKey] === TiposCamposExtraEnum.FLOTANTE)?('Flotante'):
                                    (row[column.dataKey] === TiposCamposExtraEnum.TEXTO) ?('Texto'):
                                    ('Texto con máscara')
                                    }
                                </>
                            ): column.dataKey === 'acciones' ? (
                                <>
                                    {/* <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => {
                                            setTipoCampo(row['came_tipo']);
                                            setEditingValue(row)
                                            showModalEditar()
                                        }}
                                    >
                                        <Edit/>
                                    </IconButton>  */}
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleClickDeleteCampo(row['came_id'])}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </> 
                            ): column.dataKey === 'came_fecha' ? (
                                <>{DateTime.fromISO(row['came_fecha']).toFormat("dd/MM/yyyy")}</>
                            ): column.dataKey === 'came_importante' ? (
                                <>{row['came_importante'] ? 'Sí' : 'No'}</>
                            ):(
                                (
                                    <>
                                        {
                                            row[column.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Campos extra</Typography>
                <IconButton size="small" sx={{right:0, position:'absolute', marginRight:2}}  onClick={handleShowModal}>
                    <AddCircle sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
                <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                    <TableVirtuoso
                        data={camposExtra}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        style={{
                            boxShadow:'none',
                            border:'1px solid #f2f3f3',
                            width: '100%'
                        }}
                    />
                </Paper>
            
                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModal}
                onClose={closeModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={showModal}>
                        <Box sx={style}>
                            <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <FormCampoExtra values={initialFieldsValues} tipoC={1} form={formExt} errorN={errNombre} setErrorN={setErrNombre} errDoc={errDoc} />
                                <Button variant="contained" onClick={handleCrearDatoExtra}>
                                    Guardar dato extra
                                </Button>
                            </div>
                        </Box>
                    </Fade>
                </Modal>

                <ModalEditarCampoExtra visible={visibleEditar} closeModal={closeModalEditar} >
                    <FormCampoExtra 
                    key={'form_camp_ext'} 
                    values={editingValue} 
                    tipoC={tipoCampo} 
                    form={formExt} 
                    errorN={errNombre} 
                    setErrorN={setErrNombre} 
                    errDoc={errDoc}/>
                </ModalEditarCampoExtra>
        </div>
    )
}

const FormCampoExtra = ({ values, tipoC, form, errorN, setErrorN, errDoc}) =>{

    const theme = useContext(ThemeContext);
    const [tipoCampo, setTipoCampo] = useState(tipoC);
    const [mascara, setMascara] = useState("");
    const [recurso, setRecurso] = useState(false);
    

    const handleChangeTipo = (value) => {
        setTipoCampo(parseInt(value.target.value))
    };
    const onChangeMascara = (e) => {
        setMascara(e.target.value);
    }
    const getInputTipo = () => {
        switch (tipoCampo) {
            case 1:
                return (
                    <FormControl fullWidth>
                        <Select
                            id="valor"
                            name="valor"
                            variant="standard"
                            defaultValue="No"
                            label="Tipo"
                            fullWidth
                            size="small"
                        >
                            <MenuItem value="Si">Sí</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </FormControl>
                );
            case 2:
                return <TextField
                            id="valor"
                            name="valor"
                            fullWidth
                            size="small"
                            type="text"
                            variant="standard"
                            onKeyDown={(e)=>{
                                validateNumber(e)
                            }
                            }
                            sx={{color: theme.palette.primary.input}}
                        />
            case 3:
                return <TextField multiline rows={2} key={`text-input-campo-extra`} name="valor" id="valor" />;
            case 4:
                return <ReactInputMask
                    alwaysShowMask
                    formatChars={{ "9": "[0-9]", "t": "[0-9\\-]", "?": "[0-9 ]", "a": "[a-z]", "A": "[A-Z]" }}
                    mask={mascara}
                >
                    {(inputProps) => <TextField name="mascara" id="mascara" variant="standard" {...inputProps} />}
                </ReactInputMask>
            default:
                return null;
        }
    };

    return(
        <>
            <form ref={form} id="form_ext"  style={{width: '100%', padding:'0px 10px'}}>
                <div style={{width: '100%'}}>
                    <div style={{ width: "100%", display: "flex", gap: 16, flexDirection:'column' }}>
                        <TextField
                            id="nombre"
                            label="Nombre"
                            fullWidth
                            size="small"
                            required
                            defaultValue={values?.came_nombre}
                            variant="standard"
                            type="text"
                            onChange={(e)=> {
                                e.target.value.length > 0 ?  
                                setErrorN(false) :
                                setErrorN(true)
                            }}  
                            error={errorN}
                            helperText={errorN ? 'Ingrese un valor' : ''}
                            sx={{color: theme.palette.primary.input}}
                        />

                        <FormControl fullWidth sx={{mb:-3}}>
                            <InputLabel id="demo-simple-select-label" sx={{ml:-2}}>Recurso</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="recurso"
                                name="recurso"
                                variant="standard"
                                size="small"
                                onChange={()=>setRecurso(true)}
                            >
                                {tipo_recursos_came.map(item => (
                                    <MenuItem value={item?.value}>{item?.label}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText sx={{ml:0}}>Seleccionar solo si se requiere</FormHelperText>
                        </FormControl>

                        {
                            recurso && 
                            <FormControl fullWidth error={errDoc} sx={{mt:3}}>
                                <InputLabel id="demo-simple-select-doc" sx={{ml:-2}}>Tipo documento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-doc"
                                    id="documento"
                                    name="documento"
                                    variant="standard"
                                    label="Tipo documento"
                                    size="small"
                                >
                                    {tipo_doc_came.map(item => (
                                        <MenuItem value={item?.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={{ml:0}}>{errDoc ? 'Seleccione un valor' : ''}</FormHelperText>
                            </FormControl>
                        }

                        <FormControl fullWidth sx={{mt:3}}>
                            <Select
                                id="tipo"
                                name="tipo"
                                variant="standard"
                                label="Tipo"
                                size="small"
                                required
                                onChange={handleChangeTipo}
                                defaultValue={tipoCampo}
                            >
                                <MenuItem value={1}>Boleano (Si o No)</MenuItem>
                                <MenuItem value={2}>Númerico</MenuItem>
                                <MenuItem value={3}>Texto Libre</MenuItem>
                            </Select>
                        </FormControl>

                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked={values?.came_importante} name="importante" id="importante" />} label="Importante" />
                        </FormGroup>
                    </div>
                    {
                        tipoCampo === 4 && values?.came_mascara === "" &&
                        <div style={{ width: "100%", display: "flex", gap: 16 }}>
                            <TextField
                            id="mascara"
                            label="Mascara"
                            fullWidth
                            size="small"
                            required
                            variant="standard"
                            defaultValue={values?.came_mascara}
                            sx={{color: theme.palette.primary.input}}
                            onChange={onChangeMascara}
                            />
                        </div>
                    }
                    <div style={{ width: "100%", display: "flex", gap: 16,flexDirection:'column', marginBottom:40 }}>
                        <FormControl
                            label="Valor"
                            name="valor"
                        >
                            {getInputTipo()}
                        </FormControl>
                    </div>
                </div>
            </form>
        </>
    )

}

const ModalEditarCampoExtra = ({ visible, closeModal, children }) => {
    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visible}
            onClose={closeModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={visible}>
                <Box sx={style}>
                    <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        {children}
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default EmpresaCamposExtra;