import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GetAllClientesList } from "../../../../hooks/getListClientes";

export const Header = ({setCliente, crearOrden, cliente}) => {
  const [clientes, setClientes] = useState([]);
  const { getAllClientesListMutation } = GetAllClientesList();

  const get_clientes = () => {
    getAllClientesListMutation(
      {},
      {
        onSuccess: (data) => {
          if (data.length > 0) {
            setClientes(data);
          }
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  useEffect(() => {
    get_clientes();
  }, []);



  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        sx={{ p: 2 }}
        className="center_item"
      >
        <FormControl fullWidth size="small">
          <Autocomplete
            fullWidth
            id="emp_id"
            getOptionLabel={(opcion) => opcion.label}
            filterSelectedOptions
            options={clientes}
            value={cliente}
            onChange={(e, newValue)=>{
                if(newValue === null){
                  setCliente(null)
                }else{
                  setCliente(newValue)
                }
            }}
            renderOption={(props, option)=> (
                <Box {...props} key={option?.value}>{option.label}</Box>
            )}
            renderInput={(params) => 
                <TextField {...params} label="Cliente" size="small" fullWidth  required/>
            }
            />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        sx={{ p: 2 }}
        className="center_item"
      >
        <Button variant="contained" className="btn_system" onClick={crearOrden}>
          Abrir shipper
        </Button>
      </Grid>
    </Grid>
  );
};
