import { ThemeContext } from "@emotion/react";
import { Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import moment from "moment";
import { TableVirtuoso } from "react-virtuoso";
import { Search } from "@mui/icons-material";

const TablaEntradas = ({sample, filtro, handleInputChange}) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    const rows = sample;

    const columns = [
        {
            width: 40,
            label: 'Folio',
            dataKey: 'docu_folio',
        },
        {
            width: 30,
            label: 'Fecha',
            dataKey: 'fecha',
        },
        {
            width: 20,
            label: 'Serie',
            dataKey: 'docu_serie',
        },
        {
            width: 50,
            label: 'Cliente',
            dataKey: 'cliente',
        },
        {
            width: 30,
            label: 'Estatus',
            dataKey: 'status',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    const redirect = (id) => {
        navigate(`/inventarios/entradas/${id}`);
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align="center"
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    onClick={() => redirect(row['docu_id'])}
                    >
                        {column.dataKey === 'status' ? (
                            <>
                                {row['docu_estatus'] === EstatusDocumentoEnum.SIN_ESTATUS ? ('Sin Estatus'): 
                                row['docu_estatus'] === EstatusDocumentoEnum.CANCELADO ? ('Cancelado'):
                                row['docu_estatus'] === EstatusDocumentoEnum.COBRADO ? ('Cobrado'): ('Timbrado')}
                            </>
                        ):column.dataKey === 'fecha'? (
                            <>{ moment(row['docu_fecha'], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}</>
                        ) :
                        column.dataKey === 'cliente'? (
                          <>{row?.empresas?.emp_razonsocial}</>
                        ) :
                        (
                            (
                                <>
                                    {
                                        row[column.dataKey] === null 
                                        ? '' : row[column.dataKey]}
                                </>
                            )
                        )}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }


    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                <TextField
                id="search_entry"
                label="Ingrese palabra clave"
                name="search_entry"
                size="small"
                value={filtro}
                onChange={handleInputChange}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search sx={{fontSize:20}}/>
                    </InputAdornment>
                    ),
                }}
                sx={{ color: theme.palette.primary.input }}
                />
            </Grid>
          </Grid>
          <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              style={{
                  boxShadow:'none',
                  border:'1px solid #f2f3f3'
              }}
          />
        </Paper>
    )
}

export default TablaEntradas;