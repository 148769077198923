import { ArrowBack } from "@mui/icons-material"
import { Grid, IconButton, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import { TablaPermisos } from "../../permisos/tablaPermisos";
import { GetRolPermisos } from "../../../../hooks/getPermisos";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../../redux/reducers/header.reducer";


export const  Role = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
	const dispatch = useDispatch();
  let roleId = pathname.split("/")[3];
  let roleName = pathname.split("/")[4];
  const {getRolPermisosMutation} = GetRolPermisos();
  const [permisos, setPermisos] = useState([]);

  const redirect = () => {
    navigate(`/administracion/roles`);
  }

  const get_role_permisos = () => {
    getRolPermisosMutation(roleId, {
      onSuccess: (data) => {
        setPermisos(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const get_params = (id) => {
    let params = {
      seccion: 'role',
      data: {
        roleId: roleId,
        permissionId: id
      }
    }

    return params
  }

  useEffect(() => {
    dispatch(HEADER_DATA_UPDATE({ nombreData: roleName }));
		get_role_permisos();
	}, []);

  return(
    <>
      <Grid container spacing={0} sx={{height:'6vh', pt:3}}>
        <Grid item xs={12} sx={{padding:'10px 30px', height:'100%', flexDirection: 'row', justifyContent: 'flex-start', gap:1}} className="center_item">
          <IconButton aria-label="delete" color="primary" onClick={redirect}>
            <ArrowBack />
          </IconButton>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
          >
            Permisos del Rol
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{height:'75vh', p:2}}>
        <TablaPermisos  key={'tabla_permisos'} permission_exist={permisos} get_params={get_params} updateData={get_role_permisos}/>
      </Grid>
    </>
  )
}