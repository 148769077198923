import useApi from "./useApiRequest";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

export const ReportarMovimiento = ()  => {
    const api = useApi();
    const { givenName } = useSelector((state) => state.auth.user);
    const mutate = async (params) => {
        params.usuario = givenName;
        const data  = await api.post(`/administracion-inventario/movimientos/data/reportar/validacion/no-continuo/validacion`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        reportarMovimientoMutation: mutation.mutate,
    };
}