import React, { useEffect, useState } from "react";
import { AppRegexRegisteredRoutes } from "./registered_routes";
import NotFound from "../views/errors/not_found";
import LoginRoutes from "./login_router";
import HomeRoutes from "./home_router";
import { Route, Routes, useLocation } from "react-router-dom";
import InventarioRoutes from "./inventario_router";
import { AdministracionRoutes } from "./administracion_router";
import { ReportesRouter } from "./reportes_router";

const RoutesIndex = () => {

    const location = useLocation();
    const [notFoundPage, setNotFoundPage] = useState(false);

    useEffect(() => {
        let existsRoute = AppRegexRegisteredRoutes.find(route => route.test(location?.pathname));
        existsRoute !== undefined ? setNotFoundPage(false) : setNotFoundPage(true)
    }, [location])


    return(
        <div>
            {!notFoundPage ? (
                <>
                    <LoginRoutes    />
                    <HomeRoutes    />
                    <InventarioRoutes   />
                    <AdministracionRoutes   />
                    <ReportesRouter   />
                </>
            ): (
                <Routes>
                    <Route path="*" key='not_found' element={<NotFound/>}/>
                </Routes>
            )}
        </div>
    )

}

export default RoutesIndex;