import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const ActualizarFormato = () => {
  const api = useApi();
  const mutate = async (params) => {
    const fetchData = {
      ...params,
    };
    const id = params.id;
    delete fetchData.id;
    const { data } = await api.put(`/formatos-etiquetas/${id}`, fetchData, {
      dataType: "json",
      hasFormData: false,
      hasCustomUrl: false,
    });
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    actualizarFormato: mutation.mutate,
  };
};
