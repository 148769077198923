import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetAllCategoriasList = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/categorias/list/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getAllCategoriasListMutation: mutation.mutate,
    };
}