import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import CardContacto from "./cardContacto";
import { GetContacto } from "../../../hooks/getContacto";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import Horarios from "./components/horarios";
import ContactoCamposExtra from "./components/ContactosCamposExtras";
import Elevation from "../../../components/Elevation";
import EmpresasContacto from "./components/empresasContacto";
import TelefonosContacto from "./components/telefonosContacto";


const Contacto = () => {

    const {getContactoMutation} = GetContacto();
    const dispatch = useDispatch();
    let { id } = useParams();
    const [contacto, setContacto] = useState(null);
    const [empresas, setEmpresas] = useState([])
 
    const get_contacto = () => {
        getContactoMutation(id, {
            onSuccess: (data) => {
                let empresas = data?.contactos_empresas;
                setEmpresas(empresas)
                setContacto(data)
                dispatch(
                    HEADER_DATA_UPDATE({nombreData: data?.cont_nombres})
                )
            }, 
            onError: (error) => {
                console.log(error);
            }
        })
    }

    useEffect(() => {
        get_contacto()
    }, [id])



    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{display:'flex', flexDirection:'column', p:2}}>
                <CardContacto key={'card_cont'} contacto={contacto} refetch={get_contacto}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', flexDirection:'column', p:2, gap:2}}>
                <Horarios key={'card_cont'} cont_id={contacto?.cont_id} useApi={true} horarios={contacto?.contactos_horarios} refetch={get_contacto}/>
                <ContactoCamposExtra key={'cont_ext'} cont_id={contacto?.cont_id}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', flexDirection:'column', p:2, gap:2}}>
                <Elevation style={{marginBottom:5}}>
                    <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <EmpresasContacto key={'empr_Cont'} empresas={empresas} refetch={get_contacto}/>
                    </div>
                </Elevation>
                <TelefonosContacto key={'tel_con'} cont_id={id}/>
            </Grid>
        </Grid>
    )
}

export default Contacto;