import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Button,
	Collapse,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { GetMovOrdenesNew } from "../../../../hooks/getMovOrder";
import { ThemeContext } from "@emotion/react";
import { Contenedores } from "./containers";
import { GetSalidas } from "../../../../hooks/getSalidas";
import { EstatusDocumentoEnum } from "../../../../@enums/status_documento.enum";
import { EstatusMovimientoEnum } from "../../../../@enums/status_mov_inv";
import { EliminarSalida } from "../../../../hooks/eliminarSalida";
import {
	Delete,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from "@mui/icons-material";
import { useLoading } from "../../../../components/Loading/useLoading";

export const TablaMovimientos = ({ id, empresa, status }) => {
	const { setAppLoading } = useLoading();
	const theme = useContext(ThemeContext);
	const { getMovOrdenesNewMutation } = GetMovOrdenesNew();
	const { getSalidasMutation } = GetSalidas();
	const { eliminarSalidaMutation } = EliminarSalida();
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const [contenedores, setContenedores] = useState([]);
	const [parte, setParte] = useState(null);

	const get_movimientos = () => {
		setAppLoading(true);
		getMovOrdenesNewMutation(
			{
				empresa: empresa,
				tipo:
					status === EstatusDocumentoEnum.CERRADO
						? EstatusMovimientoEnum.ENVIADO
						: EstatusMovimientoEnum.APARTADO,
				orden: id,
			},
			{
				onSuccess: (data) => {
					console.log(data)
					const data_restructured = data.reduce((acc, obj) => {
						const existingObj = acc.find(
							(item) => item.part_id === obj.part_id
						);

						if (existingObj) {
							existingObj.data.push(obj);
							existingObj.cantidad += obj.cantidad;
							existingObj.contenedores += 1;
						} else {
							acc.push({
								part_id: obj.part_id,
								nombre: obj.nombre,
								codigo_entrada: obj.codigo_entrada,
								codigo_salida: obj.codigo_salida,
								data: [obj],
								cantidad: obj.cantidad,
								contenedores: 1,
							});
						}

						return acc;
					}, []);
					setRows(data_restructured);
					setAppLoading(false);
				},
				onError: (error) => {
					console.log(error);
				},
			}
		);
	};

	useEffect(() => {
		get_movimientos();
	}, []);

	const deleteSalida = (data) => {
		setAppLoading(true);
		eliminarSalidaMutation(data, {
			onSuccess: (data) => {
				get_movimientos();
				setOpen(false);
				setAppLoading(false);
			},
			onError: (error) => {
				console.log(error);
				setAppLoading(false);
			},
		});
	};

	const Row = (props) => {
		const { row } = props;
		const [openRow, setOpenRow] = useState(false);
		const [subData, setSubData] = useState([])

		const get_container = (row) => {
			setAppLoading(true);
			setParte(row?.part_id);
			getSalidasMutation({master: row?.etiqueta, doc: id}, {
				onSuccess: (data) => {
					setContenedores(data.data);
					setAppLoading(false);
				},
				onError: (error) => {
					setAppLoading(false);
					console.log(error);
				},
			});
		};

		const openModal = (row) => {
			get_container(row);
			setOpen(true);
		};

		const openSubtabla = (data) => {
			if(openRow){
				setSubData([]);
			}else{
				let info_sub = data;
				let etiquetasProcesadas = new Map();
				for(let data of info_sub){				
					if(etiquetasProcesadas.has(data?.etiqueta)){
						etiquetasProcesadas.get(data?.etiqueta).cantidad += data?.cantidad;
						etiquetasProcesadas.get(data?.etiqueta).moin_id.push(data?.moin_id);
					}else{
						let nueva_tag = {
								cantidad: data?.cantidad,
								nombre: data?.nombre,
								codigo_entrada: data?.codigo_entrada,
								codigo_salida: data?.codigo_salida,
								isMaster: data?.isMaster,
								etiqueta: data?.etiqueta,
								part_id: data?.part_id,
								moin_id: [data?.moin_id]
						}
						etiquetasProcesadas.set(data?.etiqueta, nueva_tag);
					}
				}
				setSubData(Array.from(etiquetasProcesadas.values()));
			}
			setOpenRow(!openRow)
		}

		return (
			<React.Fragment>
				<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
					<TableCell sx={{ p: 1 }}>
						<IconButton
							aria-label="expand row"
							size="small"
							style={{ width: 25 }}
							onClick={() => openSubtabla(row?.data)}
						>
							{openRow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					</TableCell>
					<TableCell
						align="center"
						sx={{
							display: "table-cell",
							fontWeight: 500,
							color: theme.palette.primary.input,
							background: "white",
							p: 1,
						}}
					>
						{row.codigo_entrada}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							display: "table-cell",
							fontWeight: 500,
							color: theme.palette.primary.input,
							background: "white",
							p: 1,
						}}
					>
						{row.codigo_salida}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							display: "table-cell",
							fontWeight: 500,
							color: theme.palette.primary.input,
							background: "white",
							p: 1,
						}}
					>
						{row.nombre}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							display: "table-cell",
							fontWeight: 500,
							color: theme.palette.primary.input,
							background: "white",
							p: 1,
						}}
					>
						{row.cantidad}
					</TableCell>
					<TableCell
						align="center"
						sx={{
							display: "table-cell",
							fontWeight: 500,
							color: theme.palette.primary.input,
							background: "white",
							p: 1,
						}}
					>
						{row.contenedores}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={openRow} unmountOnExit>
							<Box sx={{ margin: 1 }}>
								<Typography variant="h6" gutterBottom component="div">
									Desgloce de contenedores
								</Typography>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												sx={{ color: theme.palette.primary.main, fontSize: 16 }}
											>
												Etiqueta
											</TableCell>
											<TableCell
												align="center"
												sx={{ color: theme.palette.primary.main, fontSize: 16 }}
											>
												Cantidad
											</TableCell>
											<TableCell />
											<TableCell
												align="center"
												sx={{ color: theme.palette.primary.main, fontSize: 16 }}
											>
												Acciones
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{subData.map((mov) => (
											<TableRow key={mov?.moin_id}>
												<TableCell align="center">{mov?.etiqueta}</TableCell>
												<TableCell align="center">{mov?.cantidad}</TableCell>
												<TableCell align="center">
													{mov?.isMaster && (
														<Button
															variant="text"
															className="btn_system"
															onClick={() => openModal(mov)}
														>
															Ver Detalles
														</Button>
													)}
												</TableCell>
												<TableCell align="center">
													{status === EstatusDocumentoEnum.SIN_ESTATUS && (
														<IconButton
															variant="contained"
															sx={{ p: 0, fontSize: 2, mr: -0.8, mt: -0.7 }}
															onClick={() =>
																deleteSalida({
																	moin_id: mov?.isMaster ? null : mov?.moin_id,
																	etiq_codigo: mov?.etiqueta,
																	docu_id: id,
																})
															}
														>
															<Delete sx={{ fontSize: 20, color: "#A9A9A9" }} />
														</IconButton>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	};

	return (
		<Paper
			style={{ height: 350, width: "100%", marginBottom: 10 }}
			sx={{ boxShadow: "none" }}
		>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead
						sx={{
							display: "table-head",
							backgroundColor: theme.palette.primary.main,
							fontWeight: 600,
						}}
					>
						<TableRow key="row_head_movs">
							<TableCell />
							<TableCell align="center" sx={{ color: "white" }} key="cell-entrada">
								Código entrada
							</TableCell>
							<TableCell align="center" sx={{ color: "white" }} key="cell-salida">
								Código salida
							</TableCell>
							<TableCell align="center" sx={{ color: "white" }} key="cell-nombre">
								Nombre
							</TableCell>
							<TableCell align="center" sx={{ color: "white" }} key="cell-cantidad">
								Cantidad
							</TableCell>
							<TableCell align="center" sx={{ color: "white" }} key="cell-contenedores">
								Contenedores
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.map((row) => (
							<Row key={row.serial} row={row} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Contenedores
				key={"modal_detalles"}
				open={open}
				setOpen={setOpen}
				data={contenedores}
				orden={id}
				empresa={empresa}
				parte={parte}
				setContenedores={setContenedores}
				getSalidas={getSalidasMutation}
				getMovimientos={get_movimientos}
				canDelete={status === EstatusDocumentoEnum.SIN_ESTATUS}
			/>
		</Paper>
	);
};
