import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import appReducer from "./app.reducer";
import headerReducer from "./header.reducer";

export const reducers = combineReducers({
    auth: authReducer,
    app: appReducer,
    header: headerReducer
})

