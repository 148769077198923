import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const AyudasVisuales = ({ayudas, setOpenAyudas, setAyudas}) => {


    const { apiResources } = useSelector((state) => ({
        apiResources: state.app.apiResources,
    }));

    const closeAyudas = () => {
        setOpenAyudas(false);
        setAyudas([])
    }


    return (
        <div className="contain_img_gen">
            <Button variant="contained" className="btn_system" onClick={closeAyudas} sx={{mb:1, width:'100%'}}>
                Cerrar Imagenes
            </Button>
            {ayudas.length > 0 ? (
                <>
                    {ayudas.map(item => (
                        <div className="img_item_gen">
                            <img 
                            src={`${apiResources}${item?.recu_ruta}`} 
                            alt="Imagen subida" 
                            loading="lazy" 
                            width='100%' 
                            height='100%' 
                            style={{borderRadius:5}} />
                        </div>
                    ))}
                </>
            ):(
                <span style={{marginTop:30}}>No existen imagenes para mostrar</span>
            )}
        </div>
    )
}

export default AyudasVisuales;