import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CreateCertificado = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/cuestionarios/${params.id}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearCertificadoMutation: mutation.mutate,
    };
}