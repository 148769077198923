import React from "react";
import { createUseStyles } from "react-jss";

const elevationLevels = [
    "rgba(149, 157, 165, 0.3) 0px 0px 24px",
    "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
];

const useStyles = createUseStyles({
    elevation: {
        background: "#fff",
    },
});

const Elevation = ({ children, style, className, elevationLevel, onClick }) => {
    const styles = useStyles();

    const styleTemp = {
        boxShadow: elevationLevel ? elevationLevels[elevationLevel - 1] : elevationLevels[0],
        ...(style ? style : {}),
    };
    return (
        <div style={styleTemp} className={`${styles.elevation} ${className ? className : ""}`} onClick={onClick}>
            {children}
        </div>
    );
};

export default Elevation;
