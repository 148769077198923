import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLoading } from "../components/Loading/useLoading";

export const ProtectedRoutes = ({ children, redirectTo = "/login" }) => {
    const { setAppLoading } = useLoading();
    const auth = useSelector((state) => state.auth);

    if (!auth.token) {
        setAppLoading(false);
        return <Navigate to={redirectTo} />
    }

    return children ? children : <Outlet />
}