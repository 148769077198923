import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { GetEntrada } from "../../../hooks/getEntrada";
import DataDocument from "./components/dataDocument";
import DataMovimiento from "./components/dataMovimiento";
import { UpdateDocumento } from "../../../hooks/updateDocumento";
import { CancelDocumento } from "../../../hooks/cancelDocument";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { AccionMovInvBackEnum } from "../../../@enums/action_mov_inv_back";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import processStrings from "../../../helpers/check_JSON_parse";
import { GetCamposExtraDoc } from "../../../hooks/getCamposExtraDocs";
import { TipoRecursoCameEnum } from "../../../@enums/campo_extra.enum";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { GetMovimientosByPadre } from "../../../hooks/getMovimientosByPadre";
import { PreguntasEnum } from "../../../@enums/tipo_pregunta.enum";
import { useLoading } from "../../../components/Loading/useLoading";

const Entrada = () => {
  const { id } = useParams();
  const formData = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { getEntradaMutation } = GetEntrada();
  const { updateDocumentoMutation } = UpdateDocumento();
  const { cancelDocumentoMutation } = CancelDocumento();
  const { getCamposExtraDocMutation } = GetCamposExtraDoc();
  const { getMovimientosByPadreMutation } = GetMovimientosByPadre();
  const [itemSelect, setItemSelect] = useState(null);
  const [openNew, setOpenNew] = useState(true);
  const [data, setData] = useState(null);
  const [movimientos, setMovimientos] = useState([]);
  const [movSend, setMovSend] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [inputsDoc, setInputsDoc] = useState([]);
  const [inputsMov, setInputsMov] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [inUse, setInUse] = useState(false);

  const get_entrada = () => {
    setAppLoading(true);
    getEntradaMutation(id, {
      onSuccess: (data) => {
        if (data) {
          if (data?.documentos_movimientos.length === 0) {
            get_entrada();
          }
          setData(data);
          setClientId(data?.emp_id);
          let extras = JSON.parse(data?.docu_extradata);
          let doc_extras =
            extras?.campos_extra !== undefined ? extras?.campos_extra : [];
          setInputsDoc(doc_extras);
          let mov = [];
          let mov_send = [];
          for (let item of data?.documentos_movimientos) {
            let resp;
            if (
              item?.movimientos_inventario?.cuestionarios_respuestas?.length > 0
            ) {
              resp = item?.movimientos_inventario?.cuestionarios_respuestas;
              resp?.forEach((pregunta) => {
                pregunta.cure_respuesta = processStrings(
                  pregunta?.cure_respuesta
                );
                if (pregunta.cure_tipopregunta === PreguntasEnum.MEDICIONES) {
                  pregunta.cure_opciones = processStrings(
                    pregunta?.cure_opciones
                  );
                }
              });
            } else {
              resp = [];
            }
            mov.push({
              id: item.domo_id,
              parte: item?.partes?.part_nombre,
              domo_cantidad: item?.domo_cantidad,
              serial: item?.movimientos_inventario?.moin_serial,
              domo_referencia: item?.domo_referencia,
              almacen: item?.almacenes?.alma_nombre,
              part_id: item?.part_id,
              alma_id: item?.alma_id,
              orden:
                item?.domo_extradata !== null
                  ? JSON.parse(item?.domo_extradata)?.ordenCompra
                  : null,
              cues_id: item?.movimientos_inventario?.cues_id,
              respuestas: resp,
              do_encuesta: resp.length === 0,
              extras_data:
                item?.domo_extradata !== null
                  ? JSON.parse(item?.domo_extradata)?.campos_extra
                  : null,
              spliteo: [],
              tipo_split:
                item?.domo_extradata !== null
                  ? JSON.parse(item?.domo_extradata)?.tipo_split
                  : 1,
              mov_inv: item?.moin_id,
              imgs: item?.partes?.recursos,
              nameCues:
                item?.movimientos_inventario?.cuestionarios?.cues_nombre,
            });
            mov_send.push({
              domo_id: item?.domo_id,
              domo_cantidad: item?.domo_cantidad,
              domo_referencia: item?.domo_referencia,
              part_id: item?.part_id,
              alma_id: item?.alma_id,
              moin_id: item?.moin_id,
              action: AccionMovInvBackEnum.ACTUALIZAR,
              status: item?.movimientos_inventario?.moin_estatus,
              domo_extradata: item?.domo_extradata,
              cues_id: item?.movimientos_inventario?.cues_id,
              respuestas: resp,
              do_encuesta: resp.length === 0,
              serial: item?.movimientos_inventario?.moin_serial,
            });
          }
          setMovimientos([...mov]);
          setMovSend([...mov_send]);
          setItemSelect(mov[0]);
          if (mov_send[0]?.action === AccionMovInvBackEnum.ACTUALIZAR) {
            getMovimientosByPadreMutation(
              { mov: mov[0]?.mov_inv, alm: mov[0]?.alma_id },
              {
                onSuccess: (data) => {
                  let movi = mov[0];
                  let use = data.some((item) => item?.delete === false);
                  setInUse(use);
                  movi.spliteo = data;
                  setItemSelect(movi);
                  setOpenNew(true);
                },
                onError: (error) => {
                  console.log(error);
                },
              }
            );
          }
          dispatch(
            HEADER_DATA_UPDATE({ nombreData: `Folio ${data?.docu_folio}` })
          );
          setAppLoading(false);
        } else {
          setAppLoading(false);
          swal
            .fire({
              position: "top-end",
              icon: "error",
              title: "Entrada no encontrada",
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              setTimeout(() => {
                navigate(`/inventarios/entradas`);
              }, 500);
            });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const getCamposExtra = () => {
    setAppLoading(true);
    getCamposExtraDocMutation(clientId, {
      onSuccess: (data) => {
        let inputs_mov = data.map((item) => {
          if (
            item?.came_extradata !== null &&
            parseInt(item?.came_extradata?.recurso) ===
              TipoRecursoCameEnum.MOVIMIENTO_DOC &&
            parseInt(item?.came_extradata?.tipoDocumento) ===
              TipoDocumentoEnum.ENTRADA
          ) {
            return {
              nombre_campo: item?.came_nombre,
              tipo: item?.came_tipo,
              respuesta: item?.came_valor,
            };
          }
        });
        inputs_mov = inputs_mov.reduce(
          (acc, value) => (value !== undefined ? [...acc, value] : acc),
          []
        );
        setInputsMov(inputs_mov);
        setAppLoading(false);
      },
      onError: (error) => {
        setAppLoading(false);
        console.log(error);
      },
    });
  };

  useEffect(() => {
    get_entrada();
  }, []);

  const openData = (id) => {
    getCamposExtra();
    let itemSelect = movimientos.find((obj) => obj.id === id);
    let send_Select = movSend.find((obj) => obj.domo_id === id);
    if (send_Select.action === AccionMovInvBackEnum.ACTUALIZAR) {
      getMovimientosByPadreMutation(itemSelect.mov_inv, {
        onSuccess: (data) => {
          let movi = itemSelect;
          movi.spliteo = data;
          setItemSelect(movi);
          setOpenNew(true);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      setItemSelect(itemSelect);
      setOpenNew(true);
    }
  };

  const onSave = () => {
    setIsSaving(true);
  };

  const saving_data = (array_send) => {
    let data = {
      dataDoc: {
        docu_fecha: formData.current.docu_fecha.value,
        docu_folio: formData.current.docu_folio.value,
        docu_serie: formData.current.docu_serie.value,
      },
      arrayDocMov: array_send,
    };
    updateDocumentoMutation(
      { id: id, data: data },
      {
        onSuccess: (data) => {
          if (data.success) {
            swal.fire({
              position: "top-end",
              icon: "success",
              title: data.mensaje,
              showConfirmButton: false,
              timer: 1500,
            });
            get_entrada();
          } else {
            swal.fire({
              position: "top-end",
              icon: "error",
              title: "Hubo un error al actualizar",
              text: "Intenta de nuevo",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        },
        onError: (error) => {
          console.log(error);
          swal.fire({
            position: "top-end",
            icon: "error",
            title: "Hubo un error al actualizar",
            text: "Intenta de nuevo",
            showConfirmButton: false,
            timer: 1500,
          });
        },
      }
    );
    setIsSaving(false);
  };

  const onCancel = () => {
    let data = movSend;
    swal
      .fire({
        title: "Esta seguro de cancelar la entrada",
        text: "No se prodra deshacer la acción ni recuperar los movimientos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, cancelar entrada",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setAppLoading(true);
          cancelDocumentoMutation(
            { id: id, data: data },
            {
              onSuccess: (data) => {
                swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: data.mensaje,
                  showConfirmButton: false,
                  timer: 1500,
                });
                get_entrada();
              },
              onError: (error) => {
                swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Hubo un error al cancelar",
                  text: "Intenta de nuevo",
                  showConfirmButton: false,
                  timer: 1500,
                });
                get_entrada();
              },
            }
          );
        }
      });
  };

  return (
    <Grid container spacing={0}>
      {data !== null && (
        <form ref={formData} id="form_dom" style={{ width: "100%" }}>
          <Grid item xs={12} sx={{ p: 1 }}>
            <DataDocument
              key={"data_doc_act"}
              onSave={onSave}
              data={data}
              isUpdate={true}
              onCancel={onCancel}
              extras={inputsDoc}
              inUse={inUse}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: 1 }}>
            {openNew && (
              <DataMovimiento
                key={"data_mov"}
                setOpenNew={setOpenNew}
                form={formData}
                setMovimientos={setMovimientos}
                setMovSend={setMovSend}
                movimientos={movimientos}
                movSend={movSend}
                item={itemSelect}
                setItem={setItemSelect}
                isUpdate={true}
                idClient={clientId}
                extras={inputsMov}
                getData={get_entrada}
                openData={openData}
                guarda={isSaving}
                guardarData={saving_data}
                setSaving={setIsSaving}
              />
            )}
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default Entrada;
