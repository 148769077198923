import { ThemeContext } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { GetPermisos, GrantPermisos, RevokePermisos } from "../../../hooks/getPermisos";
import { Checkbox, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";


export const TablaPermisos = ({permission_exist, get_params, updateData}) => {


  const theme = useContext(ThemeContext);
  const {getPermisosMutation} = GetPermisos();
  const {grantPermisosMutation} = GrantPermisos();
  const {revokePermisosMutation} = RevokePermisos();
  const [permisos, setPermisos] = useState([]);
  const [filtro, setFiltro] = useState('');
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const get_permisos = () => {
    getPermisosMutation({},{
      onSuccess: (data) => {
        setPermisos(data?.permissions)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    get_permisos();
  }, []);



  const Row = (props) => {

    const { row } = props;

    const handleCheck = (event, id) => {
      let params = get_params(id);
      if(event.target.checked){
        grantPermisosMutation(params, {
          onSuccess: (data) => {
            get_permisos()
            updateData()
          },
          onError: (error) => {
            console.log(error)
          }
        })
      }else{
        revokePermisosMutation(params, {
          onSuccess: (data) => {
            updateData();
          },
          onError: (error) => {
            console.log(error)
          }
        })
      }
  
    }

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0
            }}
          >
            {row.permissionId}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0
            }}
          >
            {row.permissionDescription}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0
            }}
          >
            {row.permissionKey}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0
            }}
          >
            <Checkbox
              {...label}
              defaultChecked={permission_exist.some((permission) => permission?.permissionId === row?.permissionId)}
              disabled={permission_exist.find((permission) => permission?.permissionId === row?.permissionId)?.disabled}
              onChange={(e)=> handleCheck(e, row?.permissionId)}
              sx={
                { '& .MuiSvgIcon-root': { fontSize: 28 } 
                }
              }
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const permisosFiltrados = permisos.filter((permiso) => {
    return (
      permiso.permissionDescription.toLowerCase().includes(filtro.toLowerCase()) ||
      permiso.permissionKey.toLowerCase().includes(filtro.toLowerCase())
    );
  });

  const handleInputChange = (event) => {
    setFiltro(event.target.value);
  };

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
            <TextField
              id="search_permission"
              label="Ingrese nombre o llave"
              name="search_permission"
              size="small"
              value={filtro}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{fontSize:20}}/>
                  </InputAdornment>
                ),
              }}
              sx={{ color: theme.palette.primary.input }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              display: "table-head",
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <TableRow>
              <TableCell align="center" sx={{ color: "white" }}>
                Identificador del Permiso
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Nombre del Permiso
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Llave del Permiso
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Activo
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permisosFiltrados?.map((row) => (
              <Row key={row?.permissionId} row={row}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}