import { ThemeContext } from "@emotion/react";
import { AccessTime, HistoryToggleOff } from "@mui/icons-material";
import { Backdrop, Box, Button, Fade, Grid, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { CreateTurno } from "../../../../hooks/turnos";
import swal from 'sweetalert2'


export const NuevoTurno = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
  };

  const theme = useContext(ThemeContext);
  const {createTurnoMutation} = CreateTurno();
  const [errorName, setErrorName] = useState(false);
  const [errorInicio, setErrorInicio] = useState(false);
  const [errorFinal, setErrorFinal] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    if (event.target.shiftName.value !== "" && event.target.shiftInit.value !== "" && event.target.shiftFinal.value !== "" ) {
      let data = {
        turn_nombre: event.target.shiftName.value,
        turn_horainicio: event.target.shiftInit.value,
        turn_horafin: event.target.shiftFinal.value,
      }
      createTurnoMutation(data, {
        onSuccess: (data) => {
          swal.fire({
            position: "top-end",
            icon: "success",
            title: "Turno creado con éxito",
            showConfirmButton: false
          })
          handleClose();
        },
        onError: (error) => {
          swal.fire({
            position: "top-end",
            icon: "error",
            title: "Hubo un error",
            text: "Intenta de nuevo",
            showConfirmButton: false,
            timer: 800
          })
        handleClose();
        }
      })
    } else {
      event.target.shiftName.value !== ""? setErrorName(false) : setErrorName(true);
      event.target.shiftInit.value !== "" ? setErrorInicio(false):setErrorInicio(true);
      event.target.shiftFinal.value !== "" ? setErrorFinal(false):setErrorFinal(true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sx={{ p: 0, mb: 2, pr: 1 }}
                className="center_item"
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Nuevo Turno
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 3, pr: 1 }}>
                <TextField
                  id="shiftName"
                  label="Nombre del Turno"
                  name="shiftName"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    e.target.value.length > 0
                      ? setErrorName(false)
                      : setErrorName(true);
                  }}
                  sx={{ color: theme.palette.primary.input }}
                  error={errorName}
                  helperText={errorName ? "Ingrese un valor" : ""}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 3, pr: 1 }}>
                <TextField
                  id="shiftInit"
                  label="Hora Inicial"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTime />
                      </InputAdornment>
                    ),
                  }}
                  name="shiftInit"
                  size="small"
                  type="time"
                  fullWidth
                  onChange={(e) => {
                    e.target.value.length > 0
                      ? setErrorInicio(false)
                      : setErrorInicio(true);
                  }}
                  sx={{ color: theme.palette.primary.input }}
                  error={errorInicio}
                  helperText={errorInicio ? "Ingrese un valor" : ""}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 3, pr: 1 }}>
                <TextField
                  id="shiftFinal"
                  label="Hora Final"
                  name="shiftFinal"
                  size="small"
                  type="time"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HistoryToggleOff />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e) => {
                    e.target.value.length > 0
                      ? setErrorFinal(false)
                      : setErrorFinal(true);
                  }}
                  sx={{ color: theme.palette.primary.input }}
                  error={errorFinal}
                  helperText={errorFinal ? "Ingrese un valor" : ""}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 1, pr: 1, mt: 2 }}>
                <Button
                  variant="contained"
                  className="btn_system"
                  type="submit"
                >
                  Crear Turno
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Fade>
    </Modal>
  )
}