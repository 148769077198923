import { Dangerous } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"


export const NoAccess = () => {


  return(
    <>
      <Grid container spacing={0} sx={{height: '80vh'}}>
        <Grid item xs={12} sx={{p:2, alignItems: 'center', flexDirection:'column'}} className="center_item">
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h1"
            sx={{color:'#575E3B'}}
          >
            SECCION NO AUTORIZADA
          </Typography>
          <Typography
            id="transition-modal-title"
            variant="h8"
            component="h4"
          >
            Comuniquese con su administrador para consultar sus permisos de acceso
          </Typography>
          <Dangerous sx={{color: '#575E3B', fontSize:150}}/>
        </Grid>
      </Grid>
    </>
  )
}