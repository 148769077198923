import React, { useContext, useState } from "react";
import { 
    Button,
    Grid,
    IconButton,
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Typography
} from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { TableVirtuoso } from "react-virtuoso";
import { TiposDomicilioEnum } from "../../../../@enums/tipo_domicilio.enum";
import { AddCircle, Visibility } from "@mui/icons-material";
import FormDomicilio from "./formDomicilio";

const TablaDomicilios = ({sample, clie_id, get_data}) => {

    const theme = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(null)

    const rows = sample;

    const columns = [
        {
            width: 50,
            label: 'Calle',
            dataKey: 'domi_calle',
        },
        {
            width: 40,
            label: 'Colonia',
            dataKey: 'domi_colonia',
        },
        {
            width: 40,
            label: 'Municipio',
            dataKey: 'domi_municipio',
        },
        {
            width: 50,
            label: 'Estado',
            dataKey: 'domi_estado',
        },
        {
            width: 20,
            label: 'Código Postal',
            dataKey: 'domi_cp',
        },
        {
            width: 10,
            label: 'Tipo',
            dataKey: 'domi_tipo',
        },
        {
            width: 10,
            label: 'Ver mas',
            dataKey: 'datos',
        },
    ];

    const openData = (id) => {
        let itemSelect =  rows.find(obj => obj.domi_id === id);
        setItem(itemSelect);
        setOpen(true);
    }

    const closeData = () => {
        setItem(null)
        setOpen(false)
    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        >
                            {column.dataKey === 'domi_tipo' ? (
                                <>
                                    {(row[column.dataKey] === TiposDomicilioEnum.POSTAL)?('Postal'): 
                                    (row[column.dataKey] === TiposDomicilioEnum.FISCAL) ?('Fiscal'):
                                    (row[column.dataKey] === TiposDomicilioEnum.ENVIO) ?('Envio'):
                                    ('Comercial')
                                    }
                                </>
                            ): column.dataKey === 'datos' ? (
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => openData(row['domi_id'])}
                                >
                                    <Visibility/>
                                </IconButton> 
                            ): (
                                (
                                    <>
                                        {
                                            row[column?.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }


    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Domicilios</Typography>
                <IconButton size="small" onClick={() => setOpen(true)} sx={{right:0, position:'absolute', marginRight:2}}>
                    <AddCircle sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
            {!open ? (
                <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                    <TableVirtuoso
                        data={rows}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        style={{
                            boxShadow:'none',
                            border:'1px solid #f2f3f3',
                            width: '100%'
                        }}
                    />
                </Paper>
            ): (
                <FormDomicilio  key={'form_dom'} item={item} setOpen={closeData} clie_id={clie_id} get_data={get_data}/>
            )}
        </div>
    )
}

export default TablaDomicilios;