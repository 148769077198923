import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Divider, Grid, Typography } from "@mui/material";
import { FiltrosExitencias } from "./components/filtros";
import { ReporteGeneral } from "../../../hooks/reportes";
import { TablaReportesAlmacen } from "./components/tabla_almacen";
import { TablaReportesNoAlmacen } from "./components/tabla_no_almacen";
import { ThemeContext } from "@emotion/react";


export const ExistenciasIndex = () => {

  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const {reporteGeneralMutation} = ReporteGeneral();
  const [sample, setSample] = useState([]);
  const [where, setWhere] = useState(null);

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: null})
    )
  }, []);

  const search = (body) => {
    setWhere(body)
    reporteGeneralMutation(body, {
      onSuccess: (data) =>{
        if(data.success){
          setSample(data?.data);
        }
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  return(
    <>
      <Grid container spacing={0} sx={{height:'87vh'}}>
        <Grid item xs={12} sx={{padding:'10px 20px', flexDirection:'column', justifyContent: 'flex-start'}} className="center_item">
          <FiltrosExitencias  key='filtros_existencias' search={search}/>
          <Divider  sx={{width: '100%', mt:1, mb:2}}/>
          {
            sample.length > 0 &&
            <Typography 
              sx={{
                fontWeight: 600, 
                fontSize:22, 
                mb:2,
                color: theme.palette.primary.main
              }}>
              Resultados de Búsqueda
            </Typography>
          }
          {
            sample[0]?.almacen !== null ? (
              <TablaReportesAlmacen key='tablas_almacen' sample={sample} where={where}/>
            ): (
              <TablaReportesNoAlmacen key='tabla_no_almacen' sample={sample[0]} where={where}/>
            )
          }
        </Grid>
      </Grid>
    </>
  )
}