import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, TextField, Select, InputLabel, FormControl, MenuItem, Button, OutlinedInput, Autocomplete, Box, InputAdornment} from "@mui/material";
import { GetUnidadesMedida } from "../../../../hooks/getUnidadesMedida";
import { GetAllClientesList } from "../../../../hooks/getListClientes";
import ModalImagenes from "./modales/modalImgenes";
import CarouselImg from "../../../../components/carousel";
import { ThemeContext } from "@emotion/react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { RecursosEnum } from "../../../../@enums/tipo_images.enum";
import { GetAllCategoriasList } from "../../../../hooks/getListCategorias";

const InfoLateral = ({setOpen, dataPart, unidadesValue, setUnidadesValues, setOpenUnidad, updateData, categoriaSelect, setCategoriaSelect}) => {

    const theme = useContext(ThemeContext)
    const {getUnidadesMedidaMutation} = GetUnidadesMedida();
    const {getAllClientesListMutation} = GetAllClientesList();
    const {getAllCategoriasListMutation} = GetAllCategoriasList();
    const [unidades, setUnidades] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [cliente, setCliente] = useState(dataPart?.emp_id);
    const [codeIn, setCodeIn] = useState(dataPart?.part_codigoentrada);
    const [codeOut, setCodeOut] = useState(dataPart?.part_codigosalida);
    const [codeAlt, setCodeAlt] = useState(dataPart?.part_codigoalterno);
    const [codeCont, setCodeCont] = useState(dataPart?.part_codigocontpaq);
    const [weigth, setWeigth] = useState(dataPart?.part_peso);
    const [area, setArea] = useState(dataPart?.part_area);
    const [status, setStatus] = useState(dataPart?.part_estatus);
    const [openImagen, setOpenImagen] = useState(false);

    const get_unidades = () => {
        getUnidadesMedidaMutation({},{
            onSuccess: (data) => {
                setUnidadesValues([])
                setUnidades(data)
                if(dataPart?.partes_unidades_medida.length > 0){
                    const unidadesFiltradas = data.filter((unidad) => {
                        return dataPart?.partes_unidades_medida.some((parteUnidad) => {
                            return parteUnidad.unme_id === unidad.unme_id;
                        });
                    });
                    const ids_und = unidadesFiltradas.map((un)=> un.unme_id)
                    setUnidadesValues(ids_und)
                }
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const get_clientes = () => {
        getAllClientesListMutation({},{
            onSuccess: (data) => {
                if(data.length > 0){
                    setClientes(data)
                }
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const get_categorias = () => {
        getAllCategoriasListMutation(dataPart?.part_id, {
            onSuccess: (data) => {
                if(data.length > 0){
                    setCategorias(data)
                }
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_unidades();
    }, [setOpenUnidad]);

    useEffect(() => {
        get_categorias()
        get_clientes();
    }, []);

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let catSet = categoriaSelect;
            setCategoriaSelect([
                ...catSet, 
                {label: event.target.value.toLowerCase(), value: null}
            ])
        }
    };

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:0, mb:2.5, flexDirection:'column'}} className="center_item">
                <CarouselImg key={'carousel'}   
                recursos={
                    dataPart?.recursos.filter(
                    res => +res?.recu_tipo === RecursosEnum.PRINCIPAL
                    )
                }/>
                <Button 
                variant="contained" 
                sx={{mt:2, mb:1, fontSize:10, height:25}}
                startIcon={<CloudUploadIcon sx={{fontSize:10}}/>}
                onClick={()=>setOpenImagen(true)}>
                    Añadir imagenes
                </Button>
            </Grid>
            <Grid item xs={12} sx={{p:0, marginTop:'-20px'}}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="cliente"
                        name="cliente"
                        value={cliente}
                        label="Cliente"
                        onChange={(e)=> {  
                            setCliente(e.target.value) 
                        }}
                    >
                        {
                            clientes.map((clie) => (
                                <MenuItem value={clie?.value}>{clie?.label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <TextField
                    id="input_code"
                    label="Codigo entrada"
                    fullWidth
                    size="small"
                    value={codeIn}
                    onChange={(event) => {
                        setCodeIn(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <TextField
                    id="output_code"
                    label="Codigo salida"
                    fullWidth
                    size="small"
                    value={codeOut}
                    onChange={(event) => {
                        setCodeOut(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <TextField
                    id="altern_code"
                    label="Codigo alterno"
                    fullWidth
                    size="small"
                    value={codeAlt}
                    onChange={(event) => {
                        setCodeAlt(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <TextField
                    id="contpaq_code"
                    label="Codigo contpaq"
                    fullWidth
                    size="small"
                    value={codeCont}
                    onChange={(event) => {
                        setCodeCont(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <Autocomplete
                    multiple
                    fullWidth
                    id="categorias_auto"
                    getOptionLabel={(opcion) => opcion.label}
                    filterSelectedOptions
                    options={categorias}
                    value={categoriaSelect}
                    onKeyDown={handleKeyUp}
                    onChange={(e, newValue)=>{
                        setCategoriaSelect(newValue)
                    }}
                    renderOption={(props, option)=> (
                        <Box {...props}>{option.label}</Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} label="Categorias" size="small" fullWidth />
                    }
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:3}} className="center_item">
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" >U. de medida</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="unidad_medida_id"
                        label="U. de medida"
                        value={unidadesValue}
                        onChange={(e)=> {  
                            setUnidadesValues(e.target.value) 
                        }}
                        input={<OutlinedInput label="unme_nombre"/>}
                        multiple
                    >
                        <MenuItem key={'btn_uni_new'}>
                            <Button variant="contained" 
                                    className="btn_system" 
                                    sx={{height:25}}
                                    onClick={() => setOpen(true)}>
                                + Nueva unidad
                            </Button>
                        </MenuItem>
                        {unidades.map((uni)=> (
                            <MenuItem key={uni.unme_id} value={uni.unme_id}>
                                {uni.unme_nombre} - {uni.unme_codigo}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} sx={{pr:1, mb:3}} className="center_item">
                <TextField
                    id="weigth"
                    label="Peso"
                    fullWidth
                    size="small"
                    value={weigth}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          g.
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                        setWeigth(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={6} sx={{pl:1, mb:3}} className="center_item">
                <TextField
                    id="area"
                    label="Área"
                    fullWidth
                    size="small"
                    value={area}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          dm<sup>2</sup>.
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                        setArea(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:1}} className="center_item">
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="status"
                        name="status"
                        value={status}
                        label="Status"
                        size="small"
                        onChange={(e)=> {  
                            setStatus(e.target.value) 
                        }}
                    >
                        <MenuItem value={2}>Preproducción</MenuItem>
                        <MenuItem value={1}>Activo</MenuItem>
                        <MenuItem value={0}>Inactivo</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <ModalImagenes  
                key={'modal_img'} 
                open={openImagen} 
                setOpen={setOpenImagen} 
                recursos={
                    dataPart?.recursos.filter(
                    res => +res?.recu_tipo === RecursosEnum.PRINCIPAL
                    )
                }
                id={dataPart?.part_id}
                updateData={updateData}/>
        </Grid>
    )
}

export default InfoLateral;