import React, { useContext } from "react";
import { ThemeContext } from "@emotion/react";
import { Grid, InputAdornment, Table, TableContainer, TextField } from "@mui/material";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from "react-virtuoso";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";

const TablaGeneral = ({sample, filtro, handleInputChange}) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    const columns = [
        {
            width: 50,
            label: 'Nombre',
            dataKey: 'part_nombre',
        },
        {
            width: 30,
            label: 'Código de entrada',
            dataKey: 'part_codigoentrada',
        },

        {
            width: 50,
            label: 'Cliente',
            dataKey: 'cliente',
        },
        {
            width: 30,
            label: 'Estatus',
            dataKey: 'part_estatus',
        },
    ];

    const rows = sample;

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    const redirect = (id) => {
        navigate(`/inventarios/partes/${id}`);
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align={column.numeric || false ? 'right' : 'left'}
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    onClick={() => redirect(row['part_id'])}
                    >
                        {
                          column.dataKey === 'part_estatus' ? (
                              <>
                                  {
                                    (row[column.dataKey] === 1)? 
                                    ('Activo'): ('Inactivo')
                                  }
                              </>
                          ): column.dataKey === 'cliente' ?
                          (
                            <>
                              {row?.empresas?.emp_razonsocial}
                            </>
                          ):
                          (
                              <>
                                  {
                                    row[column.dataKey] === null 
                                    ? '' : row[column.dataKey]
                                  }
                              </>
                          )
                        }
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                <TextField
                id="search_parte"
                label="Ingrese palabra clave"
                name="search_parte"
                size="small"
                value={filtro}
                onChange={handleInputChange}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search sx={{fontSize:20}}/>
                    </InputAdornment>
                    ),
                }}
                sx={{ color: theme.palette.primary.input }}
                />
            </Grid>
          </Grid>
          <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              style={{
                  boxShadow:'none',
                  border:'1px solid #f2f3f3'
              }}
          />
        </Paper>
    )
}

export default TablaGeneral;