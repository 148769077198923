import { Routes, Route} from "react-router-dom";
import Home from "../views/home/home";
import { ProtectedRoutes } from "./private_routes";

const HomeRoutes = () => {

    return(
        <Routes>
            <Route element={<ProtectedRoutes    />}>
                <Route  key='login_default' path='/' element={<Home />}/>
                <Route  key='login' path='/home' element={<Home />}/>
            </Route>
        </Routes>
    )
}

export default HomeRoutes;