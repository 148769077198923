import React, { useContext } from "react";
import { 
	Grid,
    InputAdornment,
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
		TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import { TableVirtuoso } from "react-virtuoso";
import { Search } from "@mui/icons-material";

const TablaClientes = ({sample, filtro, handleInputChange, clientesFiltrados }) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    const rows = sample;

    const columns = [
        {
            width: 50,
            label: 'Razon social',
            dataKey: 'emp_razonsocial',
        },
        {
            width: 40,
            label: 'RFC',
            dataKey: 'emp_rfc',
        },
        {
            width: 50,
            label: 'Código',
            dataKey: 'emp_codigo',
        },
        {
            width: 30,
            label: 'Estatus',
            dataKey: 'emp_estatus',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    const redirect = (id) => {
        navigate(`/inventarios/clientes/${id}`);
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align="center"
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    onClick={() => redirect(row['emp_id'])}
                    >
                        {column.dataKey === 'emp_estatus' ? (
                            <>
                                {(row[column.dataKey] === 1)? (
                                    'Activo'
                                ): ('Inactivo')}
                            </>
                        ): (
                            (
                                <>
                                    {
                                        row[column.dataKey] === null 
                                        ? '' : row[column.dataKey]}
                                </>
                            )
                        )}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                    <TextField
                    id="search_permission"
                    label="Ingrese palabra clave"
                    name="search_permission"
                    size="small"
                    value={filtro}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <Search sx={{fontSize:20}}/>
                        </InputAdornment>
                        ),
                    }}
                    sx={{ color: theme.palette.primary.input }}
                    />
                </Grid>
            </Grid>
            <TableVirtuoso
							data={clientesFiltrados}
							components={VirtuosoTableComponents}
							fixedHeaderContent={fixedHeaderContent}
							itemContent={rowContent}
							style={{
									boxShadow:'none',
									border:'1px solid #f2f3f3'
							}}
            />
        </Paper>
    )
}

export default TablaClientes;