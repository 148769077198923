import { useState } from "react";
import { Divider } from "@mui/material"
import { Header } from "./components/header"
import { Sections } from "./components/sections"
import { CrearOrdenNew } from "../../../hooks/crearOrden";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import { Salida } from "./components/salida";
import { useLoading } from "../../../components/Loading/useLoading";

export const SalidasIndex = () => {
  const {setAppLoading} = useLoading();
  const {crearOrdenNewMutation} = CrearOrdenNew();
  const [cliente, setCliente] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [ordenSelect, setOrdenSelect] = useState(null);

  const create_new_orden = () => {
    if(!cliente) return;
    let data = {
      docu_tipo: TipoDocumentoEnum.SALIDA,
      docu_folio: null,
      docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
      emp_id:cliente?.value
    }
    setAppLoading(true)
    crearOrdenNewMutation({dataDocumento: data, arrayDocMov: []},{
      onSuccess: (data) => {
        setIsCreate(true)
        setAppLoading(false)
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false)
      }
    })
  }

  return (
    <>
      {
        ordenSelect !== null ? (
          <Salida key={'salida_select'} orden={ordenSelect} setOrden={setOrdenSelect}/>
        ): (
          <>
            <Header key={'header_salida'} setCliente={setCliente} crearOrden={create_new_orden} cliente={cliente}/>
            <Divider/>
            <Sections key={'sections_salida'} create={isCreate} setCreate={setIsCreate} setOrden={setOrdenSelect}/>
          </>
        )
      }
    </>
  )
}