import { Grid } from "@mui/material"
import { GetUser } from "../../../../hooks/getUser"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { DatosGenerales } from "./infoGeneral";
import { HEADER_DATA_UPDATE } from "../../../../redux/reducers/header.reducer";
import { useDispatch } from "react-redux";
import { RolesUsuario } from "./roles";
import { TablaPermisos } from "../../permisos/tablaPermisos";
import { GetRolPermisos, GetUserPermisos } from "../../../../hooks/getPermisos";


export const User = () => {

  const { pathname } = useLocation();
	const dispatch = useDispatch();
  let username = pathname.split("/")[3];
  const {getUserMutation} = GetUser();
  const {getUserPermisosMutation} = GetUserPermisos();
  const {getRolPermisosMutation} = GetRolPermisos();
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [permisos, setPermisos] = useState([])

  const get_user = () => {
    getUserMutation(username, {
      onSuccess: (data) => {
        setUser(data);
        let rols = data?.roles.map((role) => { return {value: role?.roleId, label: role?.roleName} });
        setRoles(rols)
        dispatch(HEADER_DATA_UPDATE({ nombreData: data?.username }));
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const get_params = (id) => {
    let params = {
      seccion: 'user',
      data: {
        userId: user?.id,
        permissionId: id
      }
    }

    return params
  }

  const get_user_permisos = () => {
    return new Promise((resolve, reject) => {
      getUserPermisosMutation(user?.id, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        }
      });
    });
  };
  const get_role_permisos = (roleId) => {
    return new Promise((resolve, reject) => {
      getRolPermisosMutation(roleId, {
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        }
      });
    });
  };
  const get_all_permisos = async () => {
    try {
      const userData = await get_user_permisos();
      
      const all_permissions_roles = [];
      for (const role of user?.roles) {
        const roleData = await get_role_permisos(role?.roleId);
        all_permissions_roles.push(...roleData);
      }
  
      return { userData, all_permissions_roles };
    } catch (error) {
      console.error("Error al obtener permisos:", error);
      throw error;
    }
  };
  const mergePermisos = (all_permisos, user_permisos) => {
    const permisoMap = {};
  
    all_permisos.forEach(permiso => {
      permisoMap[permiso.permissionId] = permiso;
    });
  
    user_permisos.forEach(permiso => {
      if (!permisoMap[permiso.permissionId]) {
        permisoMap[permiso.permissionId] = permiso;
      }
    });
  
    return Object.values(permisoMap);
  };

  const get_final_permisos = () => {
    get_all_permisos().then(({ userData, all_permissions_roles }) => {
      userData.forEach(objeto => {
        objeto.disabled = false;
      });
      all_permissions_roles.forEach(objeto => {
        objeto.disabled = true;
      });
      let final_permisos = mergePermisos(all_permissions_roles, userData);

      setPermisos(final_permisos)
    });
  }

  useEffect(() => {
		get_user();
	}, []);

  useEffect(() => {
		if(user !== null){
      get_final_permisos();
    }
	}, [user]);

  return (
    <>
      <Grid container spacing={0} sx={{height: '87vh'}}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ p: 2 }} className="center_item">
          <DatosGenerales key={'generales_user'} data={user}/>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ p: 2, justifyContent: 'flex-start', flexDirection:'column', height: '90%' }} className="center_item">
          { user !== null &&
            <>
              <RolesUsuario key={'roles_user'} roles={roles} get_user={get_user} user={user?.id}/>
              <TablaPermisos  key={'permission_user'} updateData={get_user} permission_exist={permisos} get_params={get_params}/>
            </>
            }
        </Grid>
      </Grid>
    </>
  )
}