import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CargarSalida = () => {
    const api = useApi();

    const mutate = async (param) => {
        return new Promise((resolve, reject) => {
            api.post(`/administracion-inventario/crear-mov/salida/tag/${param}`, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        })
    };

    const mutation = useMutation(mutate);

    return {
        cargarSalidaMutation: mutation.mutate,
    };
}