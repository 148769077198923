import React, { useEffect, useState } from "react";
import { Modal, Fade, Box, Backdrop, Typography, Grid, TextField, Button, FormControl, InputLabel, MenuItem, Select, OutlinedInput} from "@mui/material";
import { GetAllAlmacenesList } from "../../../../../hooks/getListAlmacen";
import { CreateRutaParte } from "../../../../../hooks/createRutaParte";
import swal from 'sweetalert2'
import { TiposAlmacenEnum } from "../../../../../@enums/tipo_almacen";


const ModalRuta = ({open, setOpen, part_id, consecutivo, ruta, getData}) => {

    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    const {getAllAlmacenesListMutation} = GetAllAlmacenesList();
    const {crearRutaParteMutation} = CreateRutaParte();
    const [errorName, setErrorName] = useState(false);
    const [almacen, setAlmacen] = useState(false);
    const [errorAlmacen, setErrorAlmacen] = useState(false);
    const [listAlmacenes, setListAlmacenes] = useState([])

    const get_almacenes = () => {
        getAllAlmacenesListMutation({},{
            onSuccess: (data) => {
                let alm = data?.filter(item => item?.tipo === TiposAlmacenEnum.ALMACEN)
                setListAlmacenes(alm)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_almacenes();
    }, []);

    const onSubmit = (event) => {
        event.preventDefault()
        if( event.target.ruta_nombre.value !== '' &&
            almacen !== false){
            
            let data = {
                ruta_id: ruta,
                rupr_consecutivo: consecutivo,
                alma_id: almacen,
                rupr_nombre: event.target.ruta_nombre.value
            }
            crearRutaParteMutation({id: part_id, data: data},{
                onSuccess: (data) => {
                    if(data.success){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Se creo la ruta con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            getData()
                            handleClose()
                        })
                    }else{
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al crear ruta",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }, 
                onError: (error) => {
                    console.log(error)
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al crear ruta",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })

        }else{
            if(event.target.ruta_nombre.value === '') setErrorName(true)
            if(!almacen) setErrorAlmacen(true)
        }
    };


    const handleClose = () => setOpen(false);

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1}} className="center_item">
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    Nueva Ruta
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1}} >
                                <TextField
                                    id="ruta_nombre"
                                    label="Nombre"
                                    name="ruta_nombre"
                                    size="small"
                                    fullWidth
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorName(false) :
                                        setErrorName(true)
                                    }}  
                                    error={errorName}
                                    helperText={errorName ? 'Ingrese un valor' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1, mt:2}} >
                                <FormControl fullWidth>
                                    <InputLabel id="select_sub" style={{position:'absolute'}}>Almacen</InputLabel>
                                    <Select
                                        labelId="select_sub"
                                        id="select_sub_id"
                                        name="select_sub_nam"
                                        label="Almacen"
                                        size="small"
                                        value={almacen}
                                        onChange={(e)=> {  
                                            setAlmacen(e.target.value) 
                                            setErrorAlmacen(false)
                                        }}
                                        input={<OutlinedInput label="Almacen"/>}
                                        error={errorAlmacen}
                                        helperText={!errorAlmacen ? 'Selecciona un valor' : ''}
                                    >
                                        {listAlmacenes?.map(alm => (
                                            <MenuItem value={alm.id}>{alm.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1, mt:2}} >
                                <Button variant="contained" 
                                        className="btn_system" 
                                        type="submit"
                                >
                                    Crear Ruta
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ModalRuta;