import { useDispatch } from "react-redux";
import { GetRoles } from "../../../hooks/getRoles";
import { useEffect, useState } from "react";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Button, Divider, Grid } from "@mui/material";
import { TablaGeneral } from "./components/tablaGeneral";
import { NuevoRol } from "./components/nuevoRol";


export const RolesIndex = () => {

  const dispatch = useDispatch();
  const {getRolesMutation} = GetRoles();
  const [roles, setRoles] = useState([]);
  const [openNuevo, setOpenNuevo] = useState(false);

  const get_roles = () => {
    getRolesMutation({},{
      onSuccess: (data) => {
        setRoles(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: null})
    )
    get_roles();
  }, []);

  const handleClose = () => {
    get_roles();
    setOpenNuevo(false);
  }

  return(
    <>
      <Grid container spacing={0} sx={{height:'10vh'}}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
              <Button 
              variant="contained" 
              className="btn_system" 
              onClick={() => setOpenNuevo(true)}>
                  Nuevo Rol
              </Button>
          </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} sx={{height:'80vh'}}>
        <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
            <TablaGeneral   key={'table_general'} sample={roles}/>
        </Grid>
      </Grid>
      <NuevoRol key={'create_rol'} handleClose={handleClose} open={openNuevo}/>
    </>
  )
}