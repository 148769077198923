import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useCrearEmpresaregimen } from "../../../../../hooks/empresas";
import { useApiRegimenesFiscales } from "../../../../../hooks/regimenes_fiscales";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import swal from 'sweetalert2'

const CrearEmpresaRegimen = ({ closeModal, empresa, empresaRegimenes }) => {

    const formEmpReg = useRef(null);
    const queryClient = useQueryClient();
    const { data: apiRegimenes } = useApiRegimenesFiscales({ initialData: [] });
    const [regimenes, setRegimenes] = useState([]);
    const { saveEmpresaRegimen, isSavingEmpresaRegimen } = useCrearEmpresaregimen();
    const [regim, setRegim] = useState(null)

    useEffect(() => {
        if (apiRegimenes && apiRegimenes?.length > 0) {
            // se filtran los regimenes que ya tiene la empresa y solo se muestran los que no tiene  asignados 
            setRegimenes(
                apiRegimenes.filter(regimen => {
                    let exists = empresaRegimenes.find(erfi => erfi?.regimen?.refi_id === regimen?.refi_id)
                    return !exists;
                })
            )
        }
    }, [apiRegimenes, empresaRegimenes])

    const guardarRegimen = () => {
        if(regim !== null){
            saveEmpresaRegimen({
                emp_id: empresa?.emp_id,
                refi_id: regim
            }, {
                onSuccess: res => {
                    if (res.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Régimen creado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        queryClient.invalidateQueries(["empresas", "regimenes"]);
                        if (closeModal) closeModal();
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al crear régimen",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al crear régimen",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    return(
        <div style={{width:'100%'}}>
            <Grid container spacing={0}>
                <FormControl fullWidth >
                    <Select
                        id="refi_id"
                        name="refi_id"
                        variant="standard"
                        label="Régimen fiscal"
                        size="small"
                        required
                        fullWidth
                        onChange={(e)=> {
                            setRegim(parseInt(e.target.value))
                        }}  
                        error={regim === null}
                        helperText={regim === null ? 'Seleccione un valor' : ''}
                    >
                        {
                            regimenes.map(refi => 
                                <MenuItem key={`regimen_${refi.refi_id}`} value={refi?.refi_id}>
                                    {refi.refi_clave}{" - "}{refi.refi_nombre}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <div style={{ marginTop:20, display:'flex', justifyContent:'space-between', width:'100%', }}>
                <Button variant="outlined" onClick={closeModal}>Cancelar</Button>
                <Button variant="contained" onClick={guardarRegimen}>Guardar</Button>
            </div>
        </div>
    )
}

export default CrearEmpresaRegimen;