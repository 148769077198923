import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { TablaGeneral } from "./components/tablaGeneral"
import { GetAllUsers } from "../../../hooks/getUsers"
import { useDispatch } from "react-redux"
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer"


export const UsuariosIndex = () => {

  const dispatch = useDispatch();
  const {getAllUsersMutation} = GetAllUsers();
  const [users, setUsers] = useState([]);

  const get_all_users = () => {
    getAllUsersMutation({},{
      onSuccess: (data) => {
        setUsers(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: null})
    )
    get_all_users();
  }, []);


  return(
    <>
      <Grid container spacing={0} sx={{height:'87vh'}}>
        <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
            <TablaGeneral   key={'table_general'} sample={users}/>
        </Grid>
      </Grid>
    </>
  )
}