import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "./useApiRequest";
import { formatUrlQuery } from "./utils";

// ------------------- hooks de contactos ----------------------------

export const useApiContactos = (options, params) => {
    const api = useApi();

    const getContactos = async () => {
        // si es que se mandan parametros por la url se formatean y se agregan
        if (params) {
            const { data: resultData } = await api.get(`/contactos/${formatUrlQuery(params, true)}`);
            return resultData || [];
        }
        // sino se consultan todos los contactos
        const { data } = await api.get(`/contactos`);
        return data;
    };

    return useQuery(["contactos"], getContactos, options);
};

export const useApiContacto = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", contactoId],
        async () => {
            if (!contactoId)
                return null;
            
            const { data } = await api.get(`/contactos/${contactoId}`);
            return data;
        },
        options
    );
};

export const useCreateNewContact = () => {
    const api = useApi();

    const addResource = async (createNewContactParams) => {
        const response = await api.post(`/contactos`, createNewContactParams, { dataType: "json", hasFormData: false });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        createContact: mutation.mutate,
        isCreatingContact: mutation.isLoading,
        isCreatedContact: mutation.isSuccess,
        isCreatingContactError: mutation.isError,
    };
}

export const useContactoMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {

        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.usu_id;
            delete params.usu_id;
            const { data } = await api.put(`/contactos/${id}`, params, { dataType: "json" });
            return data;
        }
    };

    const {
        mutate: mutateContacto,
        isLoading: isLoadingContactoMutation,
        error: errorContactoMutation,
        reset: resetContactoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "horarios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContacto,
        isLoadingContactoMutation,
        errorContactoMutation,
        resetContactoMutation,
    };
}



// ------------------- hooks de empresas del contacto

export const useApiContactoEmpresas = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contactos", "empresas", contactoId],
        async () => {
            if (!contactoId) return [];
            const { data } = await api.get(`/contactos/${contactoId}/empresas`);
            return data;
        },
        options
    );
};

export const UpdateEmpresaContact = () => {
    const api = useApi();

    const updateEmpresaContact = async (params) => {
        const response = await api.put(`/contactos/${params.cont_id}/empresas/${params.emp_id}`, params.data, { dataType: "json", hasFormData: false });
        return response?.data;
    };

    const mutation = useMutation(updateEmpresaContact);

    return {
        updateEmpresaContact: mutation.mutate,
        isCreatingContact: mutation.isLoading,
        isCreatedContact: mutation.isSuccess,
        isCreatingContactError: mutation.isError,
    };
};
export const CreateEmpresaContact = () => {
    const api = useApi();

    const createEmpresaContact = async (params) => {
        const response = await api.post(`/contactos/${params.cont_id}/empresas`, params.data, { dataType: "json", hasFormData: false });
        return response?.data;
    };

    const mutation = useMutation(createEmpresaContact);

    return {
        createEmpresaContact: mutation.mutate,
        isCreatingContact: mutation.isLoading,
        isCreatedContact: mutation.isSuccess,
        isCreatingContactError: mutation.isError,
    };
};
export const DeleteEmpresaContact = () => {
    const api = useApi();

    const deleteEmpresaContact = async (params) => {
        const response = await api.del(`/contactos/${params.cont_id}/empresas/${params.emp_id}`, { dataType: "json", hasFormData: false });
        return response?.data;
    };

    const mutation = useMutation(deleteEmpresaContact);

    return {
        deleteEmpresaContact: mutation.mutate,
        isCreatingContact: mutation.isLoading,
        isCreatedContact: mutation.isSuccess,
        isCreatingContactError: mutation.isError,
    };
};

// ------- horarios de los contactos

export const useApiContactoHorarios = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", "horarios"],
        async () => {
            const { data } = await api.get(`/contactos/${contactoId}/horarios`);
            return data;
        },
        options
    );
};

export const useContactoHorarioMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.usu_id}/horarios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.emp_id}/horarios/${params.usho_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactoHorario,
        isLoading: isLoadingContactoHorarioMutation,
        error: errorContactoHorarioMutation,
        reset: resetContactoHorarioMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "horarios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactoHorario,
        isLoadingContactoHorarioMutation,
        errorContactoHorarioMutation,
        resetContactoHorarioMutation,
    };
};

// ------------------- hooks de telefonos del contacto

export const useApiContactoTelefonos = (options, contactoId) => {
    const api = useApi();
    return useQuery(
        ["contacto", "telefonos"],
        async () => {
            const { data } = await api.get(`/contactos/${contactoId}/telefonos`);
            return data;
        },
        options
    );
};

export const useContactoTelefonoMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.usu_id}/telefonos`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.usu_id}/telefonos/${params.usc_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactoTelefono,
        isLoading: isLoadingContactoTelefonoMutation,
        error: errorContactoTelefonoMutation,
        reset: resetContactoTelefonoMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contacto", "telefonos"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactoTelefono,
        isLoadingContactoTelefonoMutation,
        errorContactoTelefonoMutation,
        resetContactoTelefonoMutation,
    };
};

export const useAprobarContacto = () => {
    const api = useApi();

    const addResource = async (approvingParams) => {
        const response = await api.put(`/contactos/${approvingParams.usu_id}/aprobar`, approvingParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        approveContacto: mutation.mutate,
        isApprovingContacto: mutation.isLoading,
        isApprovedContacto: mutation.isSuccess,
        isApprovingContactoError: mutation.isError,
    };
};

// ---------------------- campos extra del contacto --------------------------

export const useApiContactosCamposExtra = (options) => {
    const api = useApi();
    return useQuery(
        ["contactos", "campos_extra"],
        async () => {
            if (options?.id) {
                const { data } = await api.get(`/contactos/${options?.id}/campos_extra`);
                return data;
            } else return []
        },
        options
    );
};

export const useContactosCamposExtraMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/contactos/${params.cont_id}/campos_extra`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/contactos/${params.cont_id}/campos_extra/${params.came_id}`);
            return data;
        }
    };

    const {
        mutate: mutateContactosCamposExtra,
        isLoading: isLoadingContactosCamposExtraMutation,
        error: errorContactosCamposExtraMutation,
        reset: resetContactosCamposExtraMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["contactos", "campos_extra"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateContactosCamposExtra,
        isLoadingContactosCamposExtraMutation,
        errorContactosCamposExtraMutation,
        resetContactosCamposExtraMutation,
    };
};
