import { EstatusMovimientoEnum } from "../@enums/status_mov_inv";
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetMovimientosByAlmacen = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/almacen/${params.id}/status/${params.status}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getMovimientosByAlmacenMutation: mutation.mutate,
    };
}

export const GetMovimientosByAlmacenCargados = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/almacen/${params.id}/status/${EstatusMovimientoEnum.CARGADO}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getMovimientosByAlmacenCargadosMutation: mutation.mutate,
    };
}

export const GetMovimientosByAlmacenProcesados = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/complete/almacen/${params.id}/status/${EstatusMovimientoEnum.PROCESADO}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getMovimientosByAlmacenProcesadosMutation: mutation.mutate,
    };
}