import React, { useContext, useState } from "react";
import { Grid, TextField, Select, InputLabel, FormControl, MenuItem} from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { TiposAlmacenEnum } from "../../../../@enums/tipo_almacen";

const tipos_almacen = [
    {
        id: 1,
        label: "Centro de trabajo"
    },
    {
        id: 2,
        label: "Bodega"
    },
    {
        id: 3,
        label: "Planta"
    },
    {
        id: 4,
        label: "Almacén"
    },
    
]

const areas_almacen = [
    {
        id: 1,
        label: "Producción"
    },
    {
        id: 2,
        label: "PT (Logística)"
    },
    {
        id: 3,
        label: "Calidad"
    },
    {
        id: 4,
        label: "Cuarentena"
    },
]

const LateralInfoAlmacen = ({data, listAlmacen, tipo, setTipo, setSeccion}) => {

    const theme = useContext(ThemeContext)

    const [nameShort, setNameShort] = useState(data?.alma_nombrecorto);
    const [capacidad, setCapacidad] = useState(data?.alma_capacidad);
    const [padre, setPadre] = useState(data?.alma_padreid);
    const [status, setStatus] = useState(data?.alma_estatus);
    const [area, setArea] = useState(data?.alma_area);

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                <TextField
                    id="nombre_corto"
                    label="Nombre corto"
                    fullWidth
                    size="small"
                    value={nameShort}
                    onChange={(event) => {
                        setNameShort(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                <TextField
                    id="capacidad"
                    label="Capacidad"
                    fullWidth
                    size="small"
                    value={capacidad}
                    onChange={(event) => {
                        setCapacidad(event.target.value);
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                <FormControl size="small" fullWidth>
                    <InputLabel id="select_padre">Almacen Padre</InputLabel>
                    <Select
                        labelId="select_padre"
                        id="id_padre"
                        name="id_padre"
                        value={padre}
                        label="Almacen Padre"
                        onChange={(e)=> {  
                            setPadre(e.target.value) 
                        }}
                    >
                        {listAlmacen?.map(alm => (
                            <MenuItem value={alm.id}>{alm.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="tipo_almacen"
                        name="tipo_almacen"
                        value={tipo}
                        label="Tipo"
                        onChange={(e)=> {  
                            setTipo(parseInt(e.target.value))
                            if(parseInt(e.target.value)===TiposAlmacenEnum.ALMACEN){
                                setSeccion(1)
                            }
                        }}
                    >
                        {tipos_almacen.map(tipo => (
                            <MenuItem value={tipo.id}>{tipo.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {
                tipo === TiposAlmacenEnum.ALMACEN && 
                <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="demo-simple-select-area">Área</InputLabel>
                        <Select
                            labelId="demo-simple-select-area"
                            id="area_almacen"
                            name="area_almacen"
                            value={area}
                            label="Área"
                            onChange={(e)=> {  
                                setArea(parseInt(e.target.value)) 
                            }}
                        >
                            {areas_almacen.map(tipo => (
                                <MenuItem value={tipo.id}>{tipo.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                
            }
            <Grid item xs={12} sx={{p:0, mb:2}} className="center_item">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="status_alm"
                        name="status_alm"
                        value={status}
                        label="Status"
                        onChange={(e)=> {  
                            setStatus(e.target.value) 
                        }}
                    >
                        <MenuItem value={1}>Activo</MenuItem>
                        <MenuItem value={0}>Inactivo</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default LateralInfoAlmacen;