import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetCertificado = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/cuestionarios/preguntas/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getCertificadoMutation: mutation.mutate,
    };
}