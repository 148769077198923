import React, { useContext, useEffect, useState } from "react";
import { Backdrop, Box, Button, Checkbox, Fade, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { AddCircle, Delete } from "@mui/icons-material";
import { TableVirtuoso } from "react-virtuoso";
import { Typography } from "antd";
import { CreateEmpresaContact, DeleteEmpresaContact, UpdateEmpresaContact } from "../../../../hooks/contactos";
import swal from 'sweetalert2'
import { useModal } from "../../../../hooks/use/useModal";
import { GetAllClientesList } from "../../../../hooks/getListClientes";
import { useParams } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center'
};

const EmpresasContacto = ({empresas, refetch}) => {

    const theme = useContext(ThemeContext);
    const {updateEmpresaContact} = UpdateEmpresaContact();
    const {createEmpresaContact} = CreateEmpresaContact();
    const {deleteEmpresaContact} = DeleteEmpresaContact();
    const [visibleCrear, showModalCrear, closeModalCrear] = useModal();
    const {getAllClientesListMutation} = GetAllClientesList();
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [errClie, setErrClie] = useState(false)
    const [main, setMain] = useState(false)
    let { id } = useParams();

    const updateContacto = (e, row) => {
        let data = {
            use_principal: e.target.checked,
            cont_id : row?.cont_id
        }
        updateEmpresaContact({cont_id: row?.use_id, emp_id: row?.emp_id, data: data},{
            onSuccess: (data) => {
                if(data.success){
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Relación actualizada con éxito",
                        showConfirmButton: false,
                        timer: 800
                    })
                    refetch()
                }else{
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al actualizar la relación",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                refetch()
            },
            onError: (error) => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Fallo al actualizar la relación",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const handleGuardarContacto = () => {
        if(cliente !== null){
            let data = {
                emp_id: parseInt(cliente),
                cont_id: id,
                use_principal: main
            }
            createEmpresaContact({cont_id: id, data:data}, {
                onSuccess: (data) => {
                    if(data.success){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Relación creada con éxito",
                            showConfirmButton: false,
                            timer: 800
                        })
                        refetch()
                        closeModalCrear();
                    }else{
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: data.mensaje,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: (error) => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un problema al crear",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            get_clientes()
        }else{
            setErrClie(true);
        }
    }

    const deleteContactEmp = (row) => {
        deleteEmpresaContact({cont_id: row?.cont_id, emp_id:row?.emp_id},{
            onSuccess: (data) => {
                if(data.success){
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Relación eliminada con éxito",
                        showConfirmButton: false,
                        timer: 800
                    })
                    refetch();
                }else{
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: data.mensaje,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                get_clientes();
            },
            onError: (error) => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Hubo un problema al crear",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const get_clientes = () => {
        getAllClientesListMutation({},{
            onSuccess: (data) => {
                if(data.length > 0){
                    const filteredArray = data.filter(item => !empresas.some(emp => emp.emp_id === item.value));
                    setClientes(filteredArray)
                }
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_clientes();
    }, []);

    const rows = empresas;

    const columns = [
        {
            width: 30,
            label: 'RFC',
            dataKey: 'rfc',
        },
        {
            width: 40,
            label: 'Razón social',
            dataKey: 'razon',
        },
        {
            width: 40,
            label: 'Nombre comercial',
            dataKey: 'nombre',
        },
        {
            width: 50,
            label: 'Principal',
            dataKey: 'principal',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        >
                            {column.dataKey === 'rfc' ? (
                                <>
                                    {row['empresas']?.emp_rfc}
                                </>
                            ): column.dataKey === 'razon' ? (
                                <>
                                    {row['empresas']?.emp_razonsocial}
                                </>
                            ) : column.dataKey === 'nombre' ? (
                                <>
                                    {row['empresas']?.emp_nombrecomercial}
                                </>
                            ) : column.dataKey === 'principal' ? (
                                <>
                                    <Checkbox
                                    defaultChecked={row['use_principal']}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={(e)=>updateContacto(e, row)}
                                    />
                                </>
                            ) :column.dataKey === 'acciones' ? (
                                <>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => deleteContactEmp(row)}
                                    >
                                        <Delete/>
                                    </IconButton> 
                                </>
                            ): (
                                (
                                    <>
                                        {
                                            row[column.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    const openModal = () => {
        get_clientes();
        showModalCrear()
    }



    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Empresas</Typography>
                <IconButton size="small" onClick={openModal} sx={{right:0, position:'absolute', marginRight:2}}>
                    <AddCircle sx={{ fontSize: 16 }}/>
                </IconButton>
            </div>
            <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                    <TableVirtuoso
                        data={rows}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        style={{
                            boxShadow:'none',
                            border:'1px solid #f2f3f3',
                            width: '100%'
                        }}
                    />
            </Paper>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visibleCrear}
            onClose={closeModalCrear}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            >
                <Fade in={visibleCrear}>
                    <Box sx={style}>
                        <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                            <FormControl fullWidth error={errClie} size="small">
                                <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="cliente"
                                    name="cliente"
                                    label="Cliente"
                                    onChange={(e)=> {  
                                        setCliente(e.target.value) 
                                        setErrClie(false)
                                    }}
                                >
                                    {
                                        clientes.map((clie) => (
                                            <MenuItem value={clie?.value}>{clie?.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{errClie ? 'Seleccione una empresa' : ''}</FormHelperText>
                            </FormControl>
                            <FormGroup sx={{mt:2, mb:2}}>
                                <FormControlLabel control={
                                    <Switch  onChange={()=>setMain(!main)}/>
                                } label="¿Es la empresa principal del contacto?" 
                                />
                            </FormGroup>
                            <Button variant="contained" 
                                className="btn_system" 
                                onClick={handleGuardarContacto}
                                >
                                Guardar
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )

    
}

export default EmpresasContacto;