import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const MergeContainers = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.put(`/administracion-inventario/contenedores/merge/split/etiquetas/movimientos/replace/data`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        mergeContainersMutation: mutation.mutate,
    };
}