import { Routes, Route} from "react-router-dom";
import Login from "../views/login/login";

const LoginRoutes = () => {

    return(
        <Routes>
            <Route  key='login' path='/login' element={<Login />}/>
        </Routes>
    )
}

export default LoginRoutes;