import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetPermisos = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/permisos`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getPermisosMutation: mutation.mutate,
    };
}

export const GetRolPermisos = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/permisos/role/${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getRolPermisosMutation: mutation.mutate,
    };
}
export const GetUserPermisos = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/permisos/user/${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getUserPermisosMutation: mutation.mutate,
    };
}

export const GrantPermisos = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/permisos/grant/${params.seccion}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        grantPermisosMutation: mutation.mutate,
    };
}

export const RevokePermisos = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/permisos/revoke/${params.seccion}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        revokePermisosMutation: mutation.mutate,
    };
}