import React, { useRef, useState } from "react";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,  } from "@mui/material";
import { useQueryClient } from "react-query";
import { useContactoHorarioMutation } from "../../../../hooks/contactos";
import swal from 'sweetalert2'
import moment from "moment";

const HorarioForm = ({closeModal, cont_id, refetch}) => {

    const formHor = useRef(null);
    const [errHI, setErrHI] = useState(false);
    const [errHF, setErrHF] = useState(false);
    const [errDI, setErrDI] = useState(false);
    const [errDF, setErrDF] = useState(false);
    const [errUH, setErrUH] = useState(false);

    const { mutateContactoHorario, isLoadingContactoHorarioMutation, resetContactoHorarioMutation } = useContactoHorarioMutation();

    const validateForm = () => {
        if(
            formHor.current?.usho_diainicial.value === '' ||
            formHor.current?.usho_diafinal.value === '' ||
            formHor.current?.usho_horainicial.value === '' ||
            formHor.current?.usho_horafinal.value === '' ||
            formHor.current?.usho_usohorario.value === '' 
        ){
            if(formHor.current?.usho_diainicial.value === '')setErrDI(true);
            if(formHor.current?.usho_diafinal.value === '')setErrDF(true);
            if(formHor.current?.usho_horainicial.value === '')setErrHI(true);
            if(formHor.current?.usho_horafinal.value === '')setErrHF(true);
            if(formHor.current?.usho_usohorario.value === '')setErrUH(true);
            return false;
        }else{
            return true;
        }
    }
    const handleGuardarHorario = () => {
        if(validateForm()){
            let data = {
                usho_diainicial: parseInt(formHor.current.usho_diainicial.value),
                usho_diafinal: parseInt(formHor.current.usho_diafinal.value),
                usho_horainicial: moment(formHor.current.usho_horainicial.value, "HH:mm").format("HH:mm:ss"),
                usho_horafinal: moment(formHor.current.usho_horafinal.value, "HH:mm").format("HH:mm:ss"),
                usho_usohorario: formHor.current.usho_usohorario.value,
            }
            mutateContactoHorario(
                { ...data, mutationType: "create", cont_id: cont_id },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            closeModal();
                            resetContactoHorarioMutation();
                            formHor.current.reset();
                            refetch()
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Horario agregado con éxito",
                                showConfirmButton: false,
                                timer: 800
                            })
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Fallo al agregar el horario",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al agregar el horario",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    }

    return (
        <form ref={formHor} style={{width: '100%'}}>
            <Grid container spacing={0} >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', p:1, }}>
                    <FormControl fullWidth error={errDI} size="small">
                        <InputLabel id="usho_diainicial_label">Día inicial</InputLabel>
                        <Select
                            labelId="usho_diainicial_label"
                            id="usho_diainicial"
                            name="usho_diainicial"
                            onChange={()=>setErrDI(false)} 
                            variant="standard"
                        >
                            <MenuItem value={1}>Lunes</MenuItem>
                            <MenuItem value={2}>Martes</MenuItem>
                            <MenuItem value={3}>Miércoles</MenuItem>
                            <MenuItem value={4}>Jueves</MenuItem>
                            <MenuItem value={5}>Viernes</MenuItem>
                            <MenuItem value={6}>Sábado</MenuItem>
                            <MenuItem value={7}>Domingo</MenuItem>
                        </Select>
                        <FormHelperText>{errDI ? 'Seleccione un día' : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', p:1, }}>
                    <FormControl fullWidth error={errDF} size="small">
                        <InputLabel id="usho_diafinal_label">Día final</InputLabel>
                        <Select
                            labelId="usho_diafinal_label"
                            id="usho_diafinal"
                            name="usho_diafinal"
                            onChange={()=>setErrDF(false)} 
                            variant="standard"
                        >
                            <MenuItem value={1}>Lunes</MenuItem>
                            <MenuItem value={2}>Martes</MenuItem>
                            <MenuItem value={3}>Miércoles</MenuItem>
                            <MenuItem value={4}>Jueves</MenuItem>
                            <MenuItem value={5}>Viernes</MenuItem>
                            <MenuItem value={6}>Sábado</MenuItem>
                            <MenuItem value={7}>Domingo</MenuItem>
                        </Select>
                        <FormHelperText>{errDF ? 'Seleccione un día' : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', p:1, }}>
                    <TextField
                    id="usho_horainicial"
                    size="small"
                    type="time"
                    fullWidth
                    variant="standard"  
                    onChange={()=>setErrHI(false)}
                    error={errHI}
                    helperText={errHI ? 'Seleccione una hora': null}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', p:1, }}>
                    <TextField
                    id="usho_horafinal"
                    size="small"
                    type="time"
                    fullWidth
                    variant="standard"  
                    onChange={()=>setErrHF(false)}
                    error={errHF}
                    helperText={errHF ? 'Seleccione una hora': null}
                    />
                </Grid>
                <Grid item xs={12} sx={{display:'flex', p:1, width: "100%"}}>
                    <FormControl fullWidth error={errDF} size="small">
                        <InputLabel id="usho_usohorario_label">Uso horario</InputLabel>
                        <Select
                            labelId="usho_usohorario_label"
                            id="usho_usohorario"
                            name="usho_usohorario"
                            onChange={()=>setErrUH(false)} 
                            variant="standard"
                        >
                            <MenuItem value="UTC-5">Zona Sureste</MenuItem>
                            <MenuItem value="UTC-6">Zona Centro</MenuItem>
                            <MenuItem value="UTC-7">Zona Pacífico</MenuItem>
                            <MenuItem value="UTC-8">Zona Noroeste</MenuItem>
                        </Select>
                        <FormHelperText>{errUH ? 'Seleccione un día' : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{display:'flex', p:1, width: "100%"}}>
                    <Button variant="contained" 
                        className="btn_system" 
                        onClick={handleGuardarHorario}
                        >
                        Crear
                    </Button>
                </Grid>
            </Grid>
        </form>
        
    )
}

export default HorarioForm;