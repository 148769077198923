import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, TextField, Autocomplete, Button, Box } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { GetContenedores } from "../../../../hooks/getContainers";
import moment from "moment";
import barcode from '../../../resources/imgs/barcode.png'
import { ThemeContext } from "@emotion/react";

const GeneralesProducto = ({revisiones, setRevisiones, setContenedor, setOpen, dataPart, open}) => {

    const theme = useContext(ThemeContext)

    const [options, setOptions] = useState([])
    const {getContenedoresMutation} = GetContenedores();
    const [notas, setNotas] = useState(dataPart?.part_observaciones)
    const [barcodeNum, setBarcodeNum] = useState(dataPart?.part_barcode)
    const [inputValue, setInputValue] = useState([]);

    const addRevision = () => {
        setRevisiones([...revisiones, {
            pare_id: revisiones[0] !== null ? revisiones[0]?.pare_id+1 : revisiones.length + 1 , 
            pare_nombre:'', 
            pare_numerodibujo:'', 
            pare_fechadibujo:'', 
            pare_cambioingenieria:'',
            nuevo: true
        }])
    }

    const handleInputChange = (e, id) => {
        const newRevisiones = [...revisiones];
        newRevisiones[id][e.target.name] = e.target.value;
        setRevisiones(newRevisiones);
    };

    const get_contenedores = () => {
        getContenedoresMutation({},{
            onSuccess: (data) => {
                const opt = data.map(con => {
                    return {
                        label: con.cont_nombre,
                        id: con.cont_id
                    }
                })
                setOptions([{label: '', id: 0}, ...opt])
                if(dataPart?.partes_contenedores.length > 0){
                    const unidadesFiltradas = data.filter((unidad) => {
                        return dataPart?.partes_contenedores.some((parteUnidad) => {
                            return parteUnidad.cont_id === unidad.cont_id;
                        });
                    });
                    const dataAutoC = unidadesFiltradas.map(un =>{return {label: un?.cont_nombre, id: un?.cont_id}})
                    setInputValue([...inputValue, ...dataAutoC])
                    setContenedor([unidadesFiltradas[0].cont_id])
                }
            }, 
            onError: (error) => {
                console.log(error)
            }
        })
    }
    const init_revisiones = () => {
        setRevisiones(dataPart?.partes_revisiones)
    }

    useEffect(() => {
        get_contenedores();
    }, [open]);

    useEffect(() => {
        init_revisiones()
    }, []);

    const handleChange = (event, newValue) => {
        setInputValue(newValue);
        setContenedor(newValue.map(val => {return val?.id}))
    };

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{p:0, mb:1, pr:1}} >
                <TextField
                    id="notes"
                    label="Notas"
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    value={notas}
                    onChange={(e)=> {  
                        setNotas(e.target.value) 
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{p:0, mb:1, flexDirection:'column', display:'flex', alignItems:'center'}}>
                <TextField
                    id="barcode"
                    label="Codigo barras"
                    fullWidth
                    size="small"
                    value={barcodeNum}
                    onChange={(e)=> {  
                        setBarcodeNum(e.target.value) 
                    }}
                    sx={{color: theme.palette.primary.input}}
                />
                <img src={barcode} alt="img_barcode" loading="lazy" style={{width:180, height:50, ml:1}}/>
            </Grid>
            <Grid item xs={12}  sx={{p:0}} >
                <Autocomplete
                multiple
                fullWidth
                id="contenedores_auto"
                getOptionLabel={(opcion) => opcion.label}
                value={inputValue}
                filterSelectedOptions
                onChange={handleChange}
                options={options}
                renderOption={(props, option)=> (
                    <Box sx={{p:1}}>
                        {option.id === 0 ? (
                            <Button variant="contained" 
                                    className="btn_system" 
                                    sx={{height:25}}
                                    onClick={() => setOpen(true)}
                                    >
                                + Nuevo contenedor
                            </Button>
                        ): (
                            <Box {...props}>{option.label}</Box>
                        )}
                    </Box>
                )}
                renderInput={(params) => 
                    <TextField {...params} label="Contenedor" size="small" />
                }
                />
            </Grid>
            <Grid item xs={12}  sx={{p:0, mt:3, display:'flex', alignItems:'center'}} >
                <span style={{fontWeight:600, color:theme.palette.primary.title}}>
                    Revisiones
                </span>
                <IconButton onClick={addRevision}>
                    <AddCircle sx={{color:theme.palette.primary.main, ml:1}}/>
                </IconButton>
                
            </Grid>
            {revisiones.map((rev, index) => (
                <>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:0, mb:1, pr:1}} >
                        <TextField
                            id={`revision${rev.pare_id}`}
                            label="Revisión"
                            fullWidth
                            size="small"
                            value={rev.pare_nombre}
                            name="pare_nombre"
                            sx={{color: theme.palette.primary.input}}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:0, mb:1, pr:1}} >
                        <TextField
                            id={`dibujo${rev.pare_id}`}
                            label="No. de dibujo"
                            fullWidth
                            size="small"
                            value={rev.pare_numerodibujo}
                            name="pare_numerodibujo"
                            sx={{color: theme.palette.primary.input}}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:0, mb:1, pr:1}} >
                        <TextField
                            id={`fecha${rev.pare_id}`}
                            type="date"
                            fullWidth
                            size="small"
                            value={moment(rev?.pare_fechadibujo).format('yyyy-MM-DD')}
                            name="pare_fechadibujo"
                            hiddenLabel
                            placeholder=""
                            sx={{color: theme.palette.primary.input}}
                            className="input-date"
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:0, mb:1, pr:1}} >
                        <TextField
                            id={`cambio${rev.pare_id}`}
                            label="Cambio de ingenieria"
                            fullWidth
                            size="small"
                            value={rev.pare_cambioingenieria}
                            name="pare_cambioingenieria"
                            sx={{color: theme.palette.primary.input}}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    </Grid>
                </>
            ))}
            
        </Grid>
    )
}

export default GeneralesProducto;