import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Button, Divider, Grid } from "@mui/material";
import { TablaTurnos } from "./components/tablaTurnos";
import { NuevoTurno } from "./components/nuevoTurno";
import { GetTurnos } from "../../../hooks/turnos";


export const TurnosIndex = () => {

  const dispatch = useDispatch();
  const {getTurnosMutation} = GetTurnos();
  const [turnos, setTurnos] = useState([]);
  const [openNuevo, setOpenNuevo] = useState(false);

  const get_turnos = () => {
    getTurnosMutation({},{
      onSuccess: (data) => {
        setTurnos(data)
      },
      onError: (error) =>{
        console.log(error)
      }
    })
  }

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: null})
    )
    get_turnos();
  }, []);

  const handleClose = () => {
    get_turnos();
    setOpenNuevo(false);
  }

  return (
    <>
    <Grid container spacing={0} sx={{height:'10vh'}}>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
            <Button 
            variant="contained" 
            className="btn_system" 
            onClick={() => setOpenNuevo(true)}>
                Nuevo Turno
            </Button>
        </Grid>
    </Grid>
    <Divider />
    <Grid container spacing={0} sx={{height:'80vh'}}>
      <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
          <TablaTurnos   key={'table_general'} sample={turnos} update_list_turnos={get_turnos}/>
      </Grid>
    </Grid>
    <NuevoTurno key={'create_turno'} handleClose={handleClose} open={openNuevo}/>
  </>
  )
}