import { useEffect, useState } from "react";
import { GetRoles } from "../../../../hooks/getRoles"
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { AssignRolUser, RemoveRolUser } from "../../../../hooks/userRol";


export const RolesUsuario = ({roles, user, get_user}) => {

  const {getRolesMutation} = GetRoles();
  const {assignRolUserMutation} = AssignRolUser();
  const {removeRolUserMutation} = RemoveRolUser();
  const [listRoles, setListRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState(roles);

  const get_roles = () => {
    getRolesMutation({},{
      onSuccess: (data) => {
        let format_roles  = data.map(rol => {return {value: rol?.roleId, label: rol?.roleName}})
        setListRoles(format_roles)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const filteredOptions = listRoles.filter(option => 
    !selectedRoles.some(selected => selected.value === option.value)
  );

  useEffect(() => {
    get_roles();
  }, []);

  const addRol = (id) => {
    let body = {
      userId: user,
      roleId: id
    }
    assignRolUserMutation(body, {
      onSuccess: (data) => {
        get_user();
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }
  const removeRol = (id) => {
    let body = {
      user: user,
      role: id
    }
    removeRolUserMutation(body, {
      onSuccess: (data) => {
        get_user();
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const handleChange = (event, newValue) => {
    setSelectedRoles(newValue);
    const addedValue = newValue.find(option => !selectedRoles.includes(option));
    const removedValue = selectedRoles.find(option => !newValue.includes(option));
    if(addedValue){
      addRol(addedValue?.value)
    }
    if(removedValue){
      removeRol(removedValue?.value)
    }
  };

  return(
    <Grid container spacing={0} sx={{p:1}}>
      <Grid item xs={12} sx={{p:0, mb:3, flexDirection: 'column', alignItems: 'flex-start'}} className="center_item">
      <Typography variant="h6" sx={{fontWeight:500}} gutterBottom component="div">
        Roles
      </Typography>
      <Autocomplete
          multiple
          fullWidth
          id="categorias_auto"
          getOptionLabel={(opcion) => opcion.label}
          filterSelectedOptions
          options={filteredOptions}
          value={selectedRoles}
          onChange={handleChange}
          renderOption={(props, option)=> (
              <Box {...props}>{option.label}</Box>
          )}
          renderInput={(params) => 
              <TextField {...params} size="small" fullWidth />
          } 
      />
      </Grid>
    </Grid>  
  )
}