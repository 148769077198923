const theme = {
    palette: {
        primary: {
            main: "#575E3B",
            title: "#000000",
            input: "rgba(0, 0, 0, 0.5)",
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#2B2F1E',
            btn: '#D9D9D9'
        }
    },
    typography: {
        fontFamily: "Roboto",
    },
};

export default theme;