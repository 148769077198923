import { Button, Grid, Typography } from "@mui/material";
import {Descriptions, Row, Table } from "antd";
import { FaCheck } from "react-icons/fa6";
import { useTheme } from "react-jss";
import './styles.css'

const VisorConstanciaFiscal = ({ constanciaInfo, onSaveDatosConstancia }) => {
    const theme = useTheme();


    const handleSaveDatosConstancia = () => {
        if (onSaveDatosConstancia) onSaveDatosConstancia(constanciaInfo);
    }

    return (
        <div style={{height:'100%'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} sx={{p:2, textAlign:'center'}} >
                    <Typography sx={{ color: "#000000", fontSize:18, mt:-2, mb:1 }}>Datos de la constancia fiscal</Typography>
                    <Button variant="contained" 
                    className="btn_system" 
                    onClick={handleSaveDatosConstancia}
                    >
                        Aplicar datos
                    </Button>    
                </Grid>
            </Grid>
            <div style={{
                
                padding: "20px 20px 40px 20px",
                background: "#FFF",
                borderRadius: 20,
                display: "flex",
                flexDirection: "column",
                gap: 10
            }}
            >
                <Grid container spacing={0} sx={{mb:3}}>
                    <Grid item xs={12} sx={{p:2, textAlign:'justify'}}>
                        <Descriptions title="Datos generales de la empresa" column={2} bordered style={{ width: "100%" }}>
                            <Descriptions.Item label="idCIF">{constanciaInfo.id_cif}</Descriptions.Item>
                            <Descriptions.Item label="RFC">{constanciaInfo.rfc}</Descriptions.Item>
                            <Descriptions.Item label="Razón social" span={2}>{constanciaInfo.razon_social}</Descriptions.Item>
                            <Descriptions.Item label="Nombre comercial" span={2}>{constanciaInfo.nombre_comercial}</Descriptions.Item>
                            <Descriptions.Item label="Régimen capital" span={2}>{constanciaInfo.regimen_capital}</Descriptions.Item>
                            <Descriptions.Item label="E-mail" span={2}>{constanciaInfo.domicilio.correo}</Descriptions.Item>
                            <Descriptions.Item label="Telefono">{constanciaInfo.domicilio.telefono}</Descriptions.Item>
                            <Descriptions.Item label="Celular">{constanciaInfo.domicilio.telefono_movil}</Descriptions.Item>
                        </Descriptions>
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{mb:3}}>
                    <Grid item xs={12} sx={{p:2, textAlign:'justify'}}>
                        <Descriptions title="Domicilio" column={4} bordered style={{ width: "100%" }}>
                            <Descriptions.Item label="Calle" span={2}>{constanciaInfo.domicilio.calle}</Descriptions.Item>
                            <Descriptions.Item label="Exteror" span={1}>{constanciaInfo.domicilio.exterior}</Descriptions.Item>
                            <Descriptions.Item label="Interior" span={1}>{constanciaInfo.domicilio.interior}</Descriptions.Item>
                            <Descriptions.Item label="Colonia" span={2}>{constanciaInfo.domicilio.colonia}</Descriptions.Item>
                            <Descriptions.Item label="Localidad" span={2}>{constanciaInfo.domicilio.localidad}</Descriptions.Item>
                            <Descriptions.Item label="Municipio" span={2}>{constanciaInfo.domicilio.municipio}</Descriptions.Item>
                            <Descriptions.Item label="Estado" span={2}>{constanciaInfo.domicilio.estado}</Descriptions.Item>
                            <Descriptions.Item label="CP" span={4}>{constanciaInfo.domicilio.codigo_postal}</Descriptions.Item>
                        </Descriptions>
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{mb:0}}>
                    <Grid item xs={12} sm={12} sx={{p:2, textAlign:'center'}} >
                        <Typography sx={{ color: "#000000", fontSize:16, mt:-2, mb:-2, fontWeight:500 }}>Actividades económicas</Typography>  
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{mb:3}}>
                    <Table
                        style={{ width: "100%" }}
                        dataSource={constanciaInfo.actividades_economicas}
                        pagination={false}
                        bordered
                        columns={[
                            { dataIndex: "nombre", title: "Actividad" },
                            { dataIndex: "porcentaje", title: "Porcentaje", width: 100, align: "center" }
                        ]}
                    />
                </Grid>
                <Grid container spacing={0} sx={{mb:0}}>
                    <Grid item xs={12} sm={12} sx={{p:2, textAlign:'center'}} >
                        <Typography sx={{ color: "#000000", fontSize:16, mt:-2, mb:-2, fontWeight:500 }}>Regímenes</Typography>  
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{mb:3}}>
                    <Table
                        style={{ width: "100%" }}
                        dataSource={constanciaInfo.regimenes_fiscales}
                        pagination={false}
                        bordered
                        columns={[
                            { dataIndex: "nombre", title: "Actividad" },
                            { dataIndex: "clave", title: "Clave SAT", width: 100, align: "center" }
                        ]}
                    />
                </Grid>
            </div>
        </div>
    );
}

export default VisorConstanciaFiscal;