import { createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_HOST, REACT_APP_RESOURCES_HOST } from "../../env";

const initialState = {
    apiHost: REACT_APP_API_HOST || "http://localhost:3001/api",
    apiResources: `${REACT_APP_RESOURCES_HOST || "http://d2dwjoj4d9sxbc.cloudfront.net/ "}`,
    printerId: null,
    loading: false
};

export const AppReducer = createSlice({
    name: "app_data",
    initialState,
    reducers: {
        APP_DATA_UPDATE: (state, action) => {
            const {apiHost, apiResources} = action.payload;
            state.apiHost = apiHost;
            state.apiResources = apiResources
        },
        SET_PRINTER_ID: (state, action) => {
            state.printerId = action.payload;
        },
        IS_LOADING: (state, action) => {
            state.loading = action.payload;
        }
    }
})

export const {APP_DATA_UPDATE, SET_PRINTER_ID, IS_LOADING} = AppReducer.actions;
export default AppReducer.reducer