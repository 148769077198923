import React, { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../hooks/use/useModal";
import { useApiEmpresaGiros, useEliminarGiroEmpresa } from "../../../../hooks/empresas";
import { Backdrop, Box, Fade, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { AddCircle, Delete } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import CrearGiro from "./giros/CrearGiro";
import ActualizarGiro from "./giros/actualizarGiro";
import swal from 'sweetalert2'

const EmpresasGiros = ({empresa}) => {

    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };


    const theme = useContext(ThemeContext);
    const [porcentajeDisponible, setPorcentajeDisponible] = useState(100);
    const [modalCrearVisible, showCrear, closeCrear] = useModal();
    const [modalEditarVisible, showEditar, closeEditar] = useModal();
    const [giroSeleccionado, setGiroSeleccionado] = useState(null);
    const { data: giros, isLoading: isLoadingGiros, refetch: refetchGiros } = useApiEmpresaGiros({ initialData: [], id: empresa?.emp_id })
    const { deleteGiroEmpresa, isDeletingGiroEmpresa } = useEliminarGiroEmpresa();

    const eliminarGiro = (giro) => {
        deleteGiroEmpresa({
            giro_id: giro.giro_id,
            emp_id: giro?.emp_id
        }, {
            onSuccess: res => {
                if (res.success) {
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Giro eliminado correctamente",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    refetchGiros();
                } else {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error al eliminar",
                        text: "Intenta de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },
            onError: () => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Hubo un error al eliminar",
                    text: "Intenta de nuevo",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchGiros();
        }
    }, [empresa])

    useEffect(() => {
        if (giros?.length > 0) {
            let porcentajeDisponible = 100 - giros.reduce((porcentaje, giro) => giro.giro_porcentaje + porcentaje, 0);
            if(giroSeleccionado){
                porcentajeDisponible = porcentajeDisponible + giroSeleccionado.giro_porcentaje;
            }
            setPorcentajeDisponible(porcentajeDisponible);
        }
    }, [giros, giroSeleccionado])

    const columns = [
        {
            width: 50,
            label: 'Nombre',
            dataKey: 'giro_nombre',
        },
        {
            width: 20,
            label: 'Porcentaje',
            dataKey: 'giro_porcentaje',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        onClick={()=>{
                            setGiroSeleccionado(row);
                            showEditar();
                        }}
                        >
                            {column.dataKey === 'acciones' ? (
                                <>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => eliminarGiro(row)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </> 
                            ):(
                                (
                                    <>
                                        {
                                            row[column.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5, justifyContent:'space-between'}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Giros</Typography>
                <IconButton size="small" sx={{right:0, justifySelf:'flex-end', marginRight:2}} onClick={showCrear}>
                    <AddCircle sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
            <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                <TableVirtuoso
                    data={giros}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                    style={{
                        boxShadow:'none',
                        border:'1px solid #f2f3f3',
                        width: '100%'
                    }}
                />
            </Paper>
            
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalCrearVisible}
                onClose={closeCrear}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={modalCrearVisible}>
                        <Box sx={style}>
                            <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <CrearGiro  key={'crear_gir'} closeModal={closeCrear} empresa={empresa} porcentajeDisponible={porcentajeDisponible}/>
                            </div>
                        </Box>
                    </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalEditarVisible}
                onClose={closeEditar}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={modalEditarVisible}>
                        <Box sx={style}>
                            <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <ActualizarGiro  
                                key={'edit_gir'} 
                                closeModal={closeEditar} 
                                empresa={empresa} 
                                porcentajeDisponible={porcentajeDisponible} 
                                giro={giroSeleccionado}/>
                            </div>
                        </Box>
                    </Fade>
            </Modal>
        </div>
    )
}

export default EmpresasGiros;