import { FormatListNumbered } from "@mui/icons-material";
import { Box, CssBaseline, styled } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import AppBarComponent from "../components/appbar";
import { ProtectedRoutes } from "./private_routes";
import { ExistenciasIndex } from "../views/reportes/existencias/existenciasIndex";
import { AccessRoutes } from "./access_route";

export const ReportesRouter = () => {
  const menu_options = [
    {
      id: 1,
      name: "Existencias",
      icon: <FormatListNumbered />,
      redirect: "/reportes/existencias",
    },
  ];

  const location = useLocation();
  let path = location?.pathname.split("/").filter((item) => item !== "");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <>
      {path[0] === "reportes" && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarComponent
            key={"app_bar_inventario"}
            menuOptions={menu_options}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            <DrawerHeader />
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route
                  key="existencias_index"
                  path="/reportes/existencias"
                  element={
                    <AccessRoutes
                      key={"access_existencias"}
                      permission={"MRP:Reportes:Existencias:*"}
                    >
                      <ExistenciasIndex key='exitencias_module'/>
                    </AccessRoutes>
                  }
                />
              </Route>
            </Routes>
          </Box>
        </Box>
      )}
    </>
  );
};
