import { ThemeContext } from "@emotion/react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { DeleteTurno } from "../../../../hooks/turnos";
import swal from 'sweetalert2'
import { Delete } from "@mui/icons-material";


export const TablaTurnos = ({sample, update_list_turnos}) => {

  const columns = [
    {
        width: 30,
        label: 'Nombre del Turno',
        dataKey: 'turn_nombre',
    },
    {
        width: 30,
        label: 'Hora Inicial',
        dataKey: 'turn_horainicio',
    },
    {
        width: 30,
        label: 'Hora Final',
        dataKey: 'turn_horafin',
    },
    {
        width: 30,
        label: 'Eliminar',
        dataKey: 'delete',
    },
  ];

  const theme = useContext(ThemeContext);
  const {deleteTurnoMutation} = DeleteTurno();

  const delete_turno = (id) => {
    deleteTurnoMutation(id, {
			onSuccess: (data) => {
				swal.fire({
						position: "top-end",
						icon: "success",
						title: "Turno eliminado con éxito",
						showConfirmButton: false
				})
				update_list_turnos()
			}, 
			onError: (error) => {
				console.log(error)
				swal.fire({
					position: "top-end",
					icon: "error",
					title: "Hubo un error",
					text: "Intenta de nuevo",
					showConfirmButton: false,
					timer: 800
				})
			}
    })
  }

  const rows = sample;

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
        <TableRow>
            {columns.map((column) => (
            <TableCell
                key={column.dataKey}
                variant="head"
                align={'center'}
                style={{ width: column.width }}
                sx={{
                    display: 'table-cell',
                backgroundColor: theme.palette.primary.main,
                color:'white',
                fontWeight:600
                }}
            >
                {column.label}
            </TableCell>
            ))}
        </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                key={column.dataKey}
                align={'center'}
                sx={{
                    display: 'table-cell !important',
                    fontWeight:500,
                    color: theme.palette.primary.input,
                    background: 'white'
                }}
                >
                    {
                        column.dataKey === 'turn_horainicio' || column.dataKey === 'turn_horafin' ? 
                        <>{row[column.dataKey].slice(0, 5)}</> :
												column.dataKey === 'delete' ? 
												<>
													<IconButton 
													variant="contained" 
													sx={{mr:1, p:1, width:'100% !important', minWidth:'20px', height:30}}  
													onClick={() => delete_turno(row.turn_id)}>
															<Delete />
													</IconButton>
												</>:
												<> {row[column.dataKey]}</> 
                    }
                </TableCell>
            ))}
        </React.Fragment>
    );
  }

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
        <TableVirtuoso
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{
                boxShadow:'none',
                border:'1px solid #f2f3f3'
            }}
        />
    </Paper>
  )
}