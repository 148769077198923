import { ThemeContext } from "@emotion/react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TableVirtuoso } from "react-virtuoso";


export const TablaGeneral = ({sample}) => {

  const columns = [
    {
        width: 30,
        label: 'Identificador del Rol',
        dataKey: 'roleId',
    },
    {
        width: 30,
        label: 'Nombre del Rol',
        dataKey: 'roleName',
    },
  ];

  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  const rows = sample;

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
        <TableRow>
            {columns.map((column) => (
            <TableCell
                key={column.dataKey}
                variant="head"
                align={'center'}
                style={{ width: column.width }}
                sx={{
                    display: 'table-cell',
                backgroundColor: theme.palette.primary.main,
                color:'white',
                fontWeight:600
                }}
            >
                {column.label}
            </TableCell>
            ))}
        </TableRow>
    );
  }

  const redirect = (id, name) => {
    navigate(`/administracion/roles/${id}/${name}`);
  }

  function rowContent(_index, row) {
    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                key={column.dataKey}
                align={'center'}
                sx={{
                    display: 'table-cell !important',
                    fontWeight:500,
                    color: theme.palette.primary.input,
                    background: 'white'
                }}
                onClick={() => redirect(row['roleId'], row['roleName'])}
                >
                    {row[column.dataKey]}
                </TableCell>
            ))}
        </React.Fragment>
    );
  }

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
        <TableVirtuoso
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{
                boxShadow:'none',
                border:'1px solid #f2f3f3'
            }}
        />
    </Paper>
  )
}