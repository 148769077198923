import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
import CanvasRenderer from "../helpers/canvasRenderer";
import {
  HorizontalRule,
  Rotate90DegreesCcw,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";

export const PreviewFormato = ({ data }) => {
  const canvasRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const biggerSide = data.foet_width > data.foet_height ? "width" : "vertical";
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const dpi = 203;
  useEffect(() => {
    // Clear al previous data, and reset rotation
    setRotation(0);
    setTranslateX(0);
    setTranslateY(0);
    handleRotation();
    handleRotation();
    handleRotation();
    const loadFonts = async () => {
      const font = new FontFace(
        "ZPL-font",
        "url(https://fonts.gstatic.com/s/francoisone/v21/_Xmr-H4zszafZw3A-KPSZut9wQiRmfW_Aw.woff2)",
        {
          style: "normal",
          weight: "400",
          stretch: "condensed",
        }
      );
      await font.load();
      document.fonts.add(font);
      document.body.classList.add("fonts-loaded");
    };

    if (!canvasRef.current) return;

    const renderer = new CanvasRenderer(canvasRef.current);
    if (!renderer.ctx) return;
    // Clean the canvas
    renderer.ctx.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    loadFonts().then(() => {
      data?.items?.forEach((item) => {
        renderer.renderItem(item, data);
      });
    });
  }, [data]);

  const handleRotation = () => {
    setRotation((prevRotation) => {
      const newRotation = (prevRotation + 90) % 360;
      switch (newRotation) {
        case 0:
          setTranslateX(0);
          setTranslateY(-(data.foet_height / 2) * dpi);
          break;
        case 90:
          setTranslateX(0);
          setTranslateY(-data.foet_height * dpi);
          break;
        case 180:
          setTranslateX(dpi * (data.foet_width / 2));
          setTranslateY(-dpi * data.foet_height);
          break;
        case 270:
          setTranslateX(dpi * (data.foet_height / 2));
          setTranslateY(-dpi * (data.foet_height / 1.6));
          break;
      }
      return newRotation;
    });
  };

  return (
    <>
      <Grid item xs={1} style={{ position: "sticky", top: 0 }}>
        <div
          style={{
            transformOrigin: "left bottom",
            transform: `translateY(${
              scale *
              data.foet_width *
              ((biggerSide === "width") * 0.5)
            }px) scale(${scale})`,
          }}
        >
          <canvas
            className="code"
            ref={canvasRef}
            width={dpi * data.foet_width}
            height={dpi * data.foet_height}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              backgroundColor: "white",
              borderColor: "black",
              zIndex: 10,
              transformOrigin: "left bottom",
              transform: `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotation}deg) scale(0.5)`,
            }}
          >
            Tu buscador no admite canvas
          </canvas>
        </div>
      </Grid>
      <Button
        type="ghost"
        style={{ position: "fixed", top: "55%", right: "10px" }}
        onClick={() => setScale((prevScale) => prevScale - 0.1)}
      >
        <ZoomOut />
      </Button>
      <Button
        type="ghost"
        style={{ position: "fixed", top: "45%", right: "10px" }}
        onClick={() => setScale((prevScale) => prevScale + 0.1)}
      >
        <ZoomIn />
      </Button>
      <Button
        type="ghost"
        style={{ position: "fixed", top: "50%", right: "10px" }}
        onClick={handleRotation}
      >
        <Rotate90DegreesCcw />
      </Button>
    </>
  );
};

export default PreviewFormato;
