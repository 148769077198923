import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, Box, Button } from "@mui/material";
import { GetAlmacen } from "../../../hooks/getAlmacen";
import { UpdateAlmacen } from "../../../hooks/updateAlmacen";
import { GetAllAlmacenesList } from "../../../hooks/getListAlmacen";
import FormDataAlmacen from "./components/formData";
import HeaderAlmacen from "./components/header";
import LateralInfoAlmacen from "./components/lateral_info";
import SubAlmacenes from "./components/sub-almacenes";
import swal from 'sweetalert2'
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { TiposAlmacenEnum } from "../../../@enums/tipo_almacen";


const DataAlmacen = () => {

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext)
    let id_parte = pathname.split('/')[3]
    const navigate = useNavigate();
    const {getAlmacenMutation} = GetAlmacen();
    const {getAllAlmacenesListMutation} = GetAllAlmacenesList();
    const {updateAlmacenMutation} = UpdateAlmacen();
    const [seccionId, setSeccionId] = useState(1);
    const [dataAlmacen, setDataAlmacen] = useState(null)
    const [listAlmacen, setListAlmacen] = useState(null)
    const [listNewSub, setListNewSub] = useState(null)
    const [color, setColor] = useState(null);
    const [tipo, setTipo] = useState(null);
    

    const changeSection = (id) => {
        setSeccionId(id)
    }

    const get_almacen = () => {    
        getAlmacenMutation(id_parte, {
            onSuccess: (data) => {
                if(data.length > 0){
                    setDataAlmacen(data[0])
                    setColor(data[0]?.alma_color !== null ? data[0]?.alma_color : '#000');
                    setTipo(data[0]?.alma_tipo);
                    dispatch(
                        HEADER_DATA_UPDATE({nombreData: data[0]?.alma_nombre})
                    )
                }else{
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Almacen no encontrado",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        setTimeout(() => {
                            navigate(`/inventarios/almacenes`);
                        }, 500);
                    });
                }
            },
            onError: (error) => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Almacen no encontrado",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    setTimeout(() => {
                        navigate(`/inventarios/almacenes`);
                    }, 1600);
                });
            }
        })
    }
    const get_almacen_list = () => {    
        getAllAlmacenesListMutation({}, {
            onSuccess: (data) => {
                if(data.length > 0){
                    setListAlmacen(data)
                    let data_sub = data.filter(alm => !alm?.isSub)
                    setListNewSub(data_sub)
                }
            },
            onError: (error) => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Almacen no encontrado",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    setTimeout(() => {
                        navigate(`/inventarios/almacenes`);
                    }, 1600);
                });
            }
        })
    }

    const btn_seccion = [
        {
            id: 1,
            name: 'Datos Generales',
            disabled: false,
            component: (
                <FormDataAlmacen
                key={'data_alm'} 
                data={dataAlmacen} 
                color={color}
                setColor={setColor}/>
            )
        },
        {
            id: 2,
            name: 'Sub-Almacenes',
            disabled: tipo === TiposAlmacenEnum.ALMACEN,
            component: (
                <SubAlmacenes 
                key={'sub_alm'}
                id={id_parte}
                subList={listNewSub}
                getList={get_almacen_list}/>
            )
        },
    ]

    useEffect(() => {
        get_almacen();
        get_almacen_list();
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            alma_nombre: event.target.nombre_almacen.value,
            alma_nombrecorto: event.target.nombre_corto.value,
            alma_estatus: +event.target?.status_alm?.value,
            alma_observaciones: event.target?.notes?.value,
            alma_color: color,
            alma_tipo: event.target?.tipo_almacen?.value === "" ? null : parseInt(event.target?.tipo_almacen?.value),
            alma_capacidad: event.target.capacidad.value,
            alma_padreid: event.target.id_padre.value === "" ? null : parseInt(event.target.id_padre.value),
            alma_area: parseInt(event.target?.tipo_almacen?.value) === TiposAlmacenEnum.ALMACEN ? parseInt(event.target?.area_almacen?.value):null
        }
        updateAlmacenMutation({id: id_parte, data: data}, {
            onSuccess: (data) => {
                if(data){
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Se completo la actualización",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        setTimeout(() => {
                            get_almacen();
                            get_almacen_list();
                        }, 1600);
                    });
                }else{
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "No se pudo actualizar",
                        text: "Intente nuevamente",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        setTimeout(() => {
                            get_almacen();
                            get_almacen_list();
                        }, 1600);
                    });
                }
            },
            onError: (error) => {
                console.log(error)
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "No se pudo actualizar",
                    text: "Intente nuevamente",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    setTimeout(() => {
                        get_almacen();
                        get_almacen_list();
                    }, 1600);
                });
            }
        })
    }

    return(
        <>
            {dataAlmacen !== null && (
                <>
                    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
                        <HeaderAlmacen key={'header_part'} data={dataAlmacen}/>
                        <Divider/>
                        <Grid container spacing={0}>
                            <Grid item xs={3} sx={{p:2, alignItems:'flex-start', pt:3}} className="center_item">
                                <LateralInfoAlmacen    
                                key={'lateral_alm'} 
                                data={dataAlmacen} 
                                listAlmacen={listAlmacen}
                                setTipo={setTipo}
                                tipo={tipo}
                                setSeccion={setSeccionId}
                                />
                            </Grid>
                            <Grid item xs={9} sx={{p:2, alignItems:'flex-start'}} className="center_item">
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sx={{p:0, borderBottom:'1px solid #C9C9C9'}} className="center_item">
                                        <Grid container spacing={0}>
                                            {btn_seccion.map((btn) => (
                                                <Grid key={btn.name} item xs={6} sm={6} md={2} lg={2} xl={2} sx={{p:1}} className="center_item">
                                                    <Button variant="contained"
                                                        className="btn_square"
                                                        disabled={btn.disabled}
                                                        sx={ seccionId === btn.id ? 
                                                            {color: theme.palette.primary.contrastText, bg: theme.palette.primary.main, fontSize:12}:
                                                            {color: theme.palette.primary.title, bgcolor: theme.palette.secondary.btn, fontSize:12}} 
                                                        onClick={()=>changeSection(btn.id)}
                                                        disableElevation>
                                                        {btn.name}
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{p:1}} className="center_item">
                                        {btn_seccion[seccionId-1]?.component}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>

    )
}

export default DataAlmacen;