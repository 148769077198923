import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetTurnos = ()  => {
    const api = useApi();

    const mutate = async () => {
        const data  = await api.get(`/turnos`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTurnosMutation: mutation.mutate,
    };
}

export const CreateTurno = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/turnos`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
      createTurnoMutation: mutation.mutate,
    };
}

export const DeleteTurno = ()  => {
  const api = useApi();

  const mutate = async (params) => {
      const data  = await api.del(`/turnos/${params}`, {
          dataType: "json",
          hasFormData: false,
          hasCustomUrl: false,
      });
      return data.data;
  };

  const mutation = useMutation(mutate);

  return {
      deleteTurnoMutation: mutation.mutate,
  };
}