import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetCamposExtra = () => {
  const api = useApi();

  const mutate = async () => {
    const { data } = await api.get(`/formatos-etiquetas/campos-extra`);
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    getAllCamposExtra: mutation.mutate,
  };
};
