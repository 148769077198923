import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const DeleteCertificado = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.del(`/cuestionarios/${params}`, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        deleteCertificadoMutation: mutation.mutate,
    };
}