import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetCamposExtraDoc = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/documentos/campos-extra/${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getCamposExtraDocMutation: mutation.mutate,
    };
}